import React from "react";
import Button from "../../Components/Button/Button";
import Input from "../../Components/input/Input";
import "./Employee.scss";
import { DEFAULT_PAGINATION } from "../partners/AllPartners";
import { sendRequest } from "../../helper/forCountries/forCountries";
import Loader from "../../Components/loader/loader";
import {
  faCogs,
  faUserPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddEmployee from "./addEmployee/AddEmployee";
import EditEmployee from "./editEmployee/EditEmployee";
import {getPages} from "../../helper/pagination/pagination";
import Modal from 'react-modal';
import {translateType} from "../../helper/sort";
import ChangePassword from "../changePassword/changePassword";

export default class AllEmployee extends React.Component {
  state = {
    pagination: { pageRequest: { ...DEFAULT_PAGINATION } },
    page: 1,
    users: {},
    changeModal: false,
    addModal: false,
    passwordModal: false,
    loader: true,
    id: "",
  };
  componentDidMount() {
    this.sendRequest();
  }
  sendRequest = () => {
    sendRequest("/api/v1/employees/search", this.state.pagination)
      .then((data) =>
        this.setState({ ...this.state, users: data.data, loader: false })
      )
      .catch((e) => console.error("error: ", e));
  };
  getEmployee = () => {
    if (this.state.users) {
      return this.state.users.content.filter(itm => itm.role.code !== 'SYSTEM_ADMIN').map((item) => {
        return (
          <tr key={item.id}>
            <td data-label="ID">{item.id}</td>
            <td data-label="Логин">{item.username}</td>
            <td data-label="Тип управления">{item.role.name}</td>
            <td data-label="Email">{item.email}</td>
            <td data-label="Статус">{translateType(item.status)}</td>
            <td data-label="Изменить">
              <Button
                variant="secondary"
                onClick={this.openChangeModal("changeModal",item.id)}
              >
                <FontAwesomeIcon icon={faWrench} /> Изменить
              </Button>
              <Button
                variant="secondary"
                onClick={this.openChangeModal("passwordModal",item.id)}
              >
                <FontAwesomeIcon icon={faWrench} /> Пароль
              </Button>
            </td>
          </tr>
        );
      });
    }
  };
  changePage = (page) => () => {
    this.setState(
      {
        ...this.state,
        pagination: {
          ...this.state.pagination,
          pageRequest: { page: page, limit: DEFAULT_PAGINATION.limit },
        },
      },
      () =>
        sendRequest("/api/v1/employees/search", this.state.pagination)
          .then((data) =>
            this.setState({
              users: data.data,
            })
          )
          .catch((e) => console.error("error: ", e))
    );
  };
  changeStateData = (e) => {
    this.setState(
      {
        ...this.state,
        pagination: {
          ...this.state.pagination,
          pageRequest: { ...DEFAULT_PAGINATION },
          filter: {
            searchParameter: e.target.value,
          },
        },
      },
      () =>
        sendRequest("/api/v1/employees/search", this.state.pagination)
          .then((data) =>
            this.setState({
              users: data.data,
            })
          )
          .catch((e) => console.error("error: ", e))
    );
  };
  openAddModal = () =>  {
    this.setState({ ...this.state, addModal: true })
  };
  openChangeModal = (data ,id) => () => {
    this.setState({ ...this.state, id: id, [data]: true })
  };
  changeModalState = (data) => () => {
    this.setState({ ...this.state, [data]: false }, () => this.sendRequest());
  };
  render() {
    return (
      <React.Fragment>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            <div className="categories_bar">
              <div className="active-header">
                <Input
                  variant="search"
                  text="Найти"
                  placeholder="Поиск..."
                  onChange={this.changeStateData}
                />
                <div className="active-header-button">
                <Button variant="blue" onClick={this.openAddModal}>
                  <FontAwesomeIcon icon={faUserPlus} /> Добавить
                </Button>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Логин</th>
                    <th scope="col">Тип управления</th>
                    <th scope="col">Электронная почта</th>
                    <th scope="col">Статус</th>
                    <th scope="col">
                      <span>
                        <FontAwesomeIcon icon={faCogs} />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>{this.getEmployee()}</tbody>
              </table>
            </div>
            <div className="span-page">{getPages(this.state.users, this.state.pagination.pageRequest.page, this.changePage)}</div>
          </>
        )}
          <Modal
            isOpen={this.state.addModal}
            onRequestClose={this.changeModalState("addModal")}
            className="modal"
            overlayClassName="overlay"
          >
            <span className="exit-span" onClick={this.changeModalState("addModal")}>X</span>
            <div>
              <AddEmployee />
            </div>
          </Modal>
          <Modal
            className="modal"
            overlayClassName="overlay"
            isOpen={this.state.changeModal}
            onRequestClose={this.changeModalState("changeModal")}
          >
            <span className="exit-span" onClick={this.changeModalState("changeModal")}>X</span>
            <div>
              <EditEmployee id={this.state.id} />
            </div>
          </Modal>
        <Modal
          className="modal"
          overlayClassName="overlay"
          isOpen={this.state.passwordModal}
          onRequestClose={this.changeModalState("passwordModal")}
        >
          <span className="exit-span" onClick={this.changeModalState("passwordModal")}>X</span>
          <div>
            <ChangePassword url={`/api/v1/employees/${this.state.id}/change-password`} />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
