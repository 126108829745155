import React from "react";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import ReactSelect from "../../../Components/react-select/react-select";
import API from "../../../Utils/API";
import "./addEmployee.scss";
import { getRequest } from "../../../helper/forCountries/forCountries";
import {
  validationOnEmail,
  validationOnPassword,
  validationOnUsersName,
} from "../../../helper/validateHelper/validateHelper";
import { ADD_EMPLOYEE } from "../../../helper/forAddPartner/helperForAddPartner";

class AddEmployee extends React.Component {
  state = {
    ...ADD_EMPLOYEE,
    disabled: true,
    incorrectText: false,
    successText: false,
    errorText: "",
  };
  componentDidMount() {
    getRequest("/api/v1/roles")
      .then((data) =>
        this.setState({
          usersRole: data.data.data,
        })
      )
      .catch((e) => console.error("error: ", e));
  }
  sendRequest = async () => {
    try {
      this.setState({ disabled: true });
      await API().post("/api/v1/employees", this.state.body, {
        headers: { "Content-Type": "application/json" },
      });
      this.setState({
        ...ADD_EMPLOYEE,
        disabled: true,
        successText: true,
        incorrectText: false,
      });
    } catch (e) {
      this.setState({
        ...this.state,
        incorrectText: true,
        successText: false,
        disabled: false,
        errorText: e.response.data.message,
      });
    }
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  changeUserRole = (foo) => (selectedOption) => {
      this.setState(
        {
          ...this.state,
          body: {...this.state.body, roleCode:selectedOption ? selectedOption.value : ""},
        },
        () => {
          foo();
        }
      );
  };
  validateLength = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnPassword(this.state.body[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(this.state.body[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateEmail = () => {
    this.setState(
      {
        ...this.state,
        email: {
          ...this.state.email,
          email: validationOnEmail(this.state.body.email),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isDisabledButton = () => {
    const state = this.state;
    return (
      state.username.username === false &&
      state.password.password === false &&
      state.email.email === false &&
      state.firstName.firstName === false &&
      state.surname.surname === false &&
      state.roleCode.roleCode === false
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  options = () => {
    return this.state.usersRole.filter(itm => itm.code !== 'SYSTEM_ADMIN').map((item) => {
      return {
        label: item.name,
        value: item.code,
      };
    });
  };
  render() {
    let buttonType = "primary"
    if(this.state.disabled === false){
      buttonType = "secondary"
    }
    return (
      <div className="modal-page">
        <span>Добавить администратора</span>
        <div className="categories_bar">
          <div className="active-header add">
            <div>
              <div className="input-height">
                <Input
                  star={true}
                  text="Логин"
                  onBlur={this.isTouched(
                    "username",
                    this.validateUsersDataLength("username", 3)
                  )}
                  placeholder="Введите логин"
                  value={this.state.body.username}
                  required={this.isRequired("username")()}
                  validationMessage="Длина должна быть не меньше 3 символов"
                  onChange={this.changeUserData(
                    "username",
                    this.validateUsersDataLength("username", 3)
                  )}
                />
              </div>
              <div className="input-height">
                <Input
                  star={true}
                  text="Пароль"
                  onBlur={this.isTouched(
                    "password",
                    this.validateLength("password")
                  )}
                  placeholder="Введите пароль"
                  value={this.state.body.password}
                  onChange={this.changeUserData(
                    "password",
                    this.validateLength("password")
                  )}
                  required={this.isRequired("password")()}
                  validationMessage="Длина пароля должно быть не меньше 6 символов"
                />
              </div>
              <div className="input-height">
                <Input
                  star={true}
                  text="Email"
                  onBlur={this.isTouched("email", this.validateEmail)}
                  placeholder="Введите мэйл"
                  value={this.state.body.email}
                  onChange={this.changeUserData("email", this.validateEmail)}
                  required={this.isRequired("email")()}
                  validationMessage="некорректный Email"
                />
              </div>
            </div>
            <div>
              <div className="input-height">
                <Input
                  star={true}
                  text="Фамилия"
                  onBlur={this.isTouched(
                    "surname",
                    this.validateUsersDataLength("surname", 3)
                  )}
                  placeholder="Введите фамилию"
                  value={this.state.body.surname}
                  onChange={this.changeUserData(
                    "surname",
                    this.validateUsersDataLength("surname", 3)
                  )}
                  required={this.isRequired("surname")()}
                  validationMessage="Длина не должна быть меньше 3 букв"
                />
              </div>
              <div className="input-height">
                <Input
                  star={true}
                  text="Имя"
                  onBlur={this.isTouched(
                    "firstName",
                    this.validateUsersDataLength("firstName", 3)
                  )}
                  placeholder="Введите имя"
                  value={this.state.body.firstName}
                  required={this.isRequired("firstName")()}
                  validationMessage="Длина не должна быть меньше 3 букв"
                  onChange={this.changeUserData(
                    "firstName",
                    this.validateUsersDataLength("firstName", 3)
                  )}
                />
              </div>
              <div className="input-height">
                <ReactSelect
                  star={true}
                  menuPlacement="top"
                  className="Select-menu-outer"
                  placeholder="Выберите роль пользователя..."
                  text="Роль"
                  onBlur={this.isTouched(
                    "roleCode",
                    this.validateUsersDataLength("roleCode", 1)
                  )}
                  onChange={this.changeUserRole(
                    this.validateUsersDataLength("roleCode", 1)
                  )}
                  options={this.options()}
                  required={this.isRequired("roleCode")()}
                  validationMessage="Поле не должно быть пустым"
                />
              </div>
            </div>
          </div>
          <div className="btn-text-center">
            <Button
              className="btn-add"
              variant={buttonType}
              onClick={this.sendRequest}
              disabled={this.state.disabled}
            >
              Добавить
            </Button>
            {this.state.incorrectText && (
              <h3 className="add-wrong">
                {this.state.errorText}
              </h3>
            )}
            {this.state.successText && (
              <h3 className="add-correct">Успешно зарегистрировано!</h3>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AddEmployee;
