import React from "react";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import {
  getRequest,
  getOptions,
  sendRequestToEdit,
} from "../../../helper/forCountries/forCountries";
import ReactSelect from "../../../Components/react-select/react-select";
import {
  validationForNumeric,
  validationOnUsersName,
} from "../../../helper/validateHelper/validateHelper";
import Loader from "../../../Components/loader/loader";

export default class EditRegion extends React.Component {
  id = this.props.id;
  state = {
    loader: true,
    index: null,
    body: {
      name: "",
      key: "",
      countryId: "",
    },
    name: {
      name: false,
      touched: false,
    },
    key: {
      key: false,
      touched: false,
    },
    countryId: {
      countryId: false,
      touched: false,
    },
    disabled: true,
    countries: [],
    incorrectText: false,
    successText: false,
    errorText: "",
  };
  componentDidMount() {
    getRequest("/api/v1/common/countries").then((data) =>
      this.setState({ ...this.state, countries: data.data.data }, () =>
        getRequest(`/api/v1/common/regions/${this.id}`)
          .then((data) =>
            this.setState(
              {
                ...this.state,
                loader: false,
                body: {
                  ...this.state.body,
                  name: data.data.name,
                  key: data.data.id,
                  countryId: data.data.country.id,
                },
              },
              () =>
                this.setState({
                  ...this.state,
                  index: this.setSelectDefaultValue(),
                })
            )
          )
          .catch((e) => console.log("erlan: ", e))
      )
    );
  }
  setSelectDefaultValue = () => {
    const arr = [];
    this.state.countries.map((item) => arr.push(item.id));
    return arr.indexOf(this.state.body.countryId);
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  isSendRequest = (url, body) => () => {
    return sendRequestToEdit(url, body)
      .then(() => {
        this.setState({ ...this.state, successText: true });
      })
      .catch((e) =>
        this.setState({
          ...this.state,
          successText: false,
          incorrectText: true,
          errorText: e.response.data.message
        })
      );
  };
  changeCountryId = (foo) => (e) => {
      this.setState(
        {
          ...this.state,
          body: {
            ...this.state.body,
            countryId: e ? e.value : "",
          },
          incorrectText: false,
          successText: false,
        },
        () => {
          foo();
        }
      );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(this.state.body[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateForNumeric = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationForNumeric(this.state.body[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  isDisabledButton = () => {
    const state = this.state;
    return (
      state.name.name === false &&
      state.key.key === false &&
      state.countryId.countryId === false
    );
  };
  render() {
    let buttonType = "primary"
    if(this.state.disabled === false){
      buttonType = "secondary"
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="15px" height="15px" />
          </div>
        ) : (
          <>
            {this.state.index !== null && (
              <div className="modal-page">
                <span>Изменить данные региона</span>
                <div className="categories_bar">
                  <Input
                    star={true}
                    text="Название региона"
                    value={this.state.body.name}
                    onChange={this.changeUserData(
                      "name",
                      this.validateUsersDataLength("name", 1)
                    )}
                    onBlur={this.isTouched(
                      "name",
                      this.validateUsersDataLength("name", 1)
                    )}
                    required={this.isRequired("name")()}
                    validationMessage="Поле не должно быть пустым"
                  />
                  <Input
                    star={true}
                    text="ID региона"
                    value={this.state.body.key}
                    onChange={this.changeUserData(
                      "key",
                      this.validateForNumeric("key")
                    )}
                    onBlur={this.isTouched(
                      "key",
                      this.validateForNumeric("key")
                    )}
                    required={this.isRequired("key")()}
                    validationMessage="Поле не должно быть пустым и содержать только цифру(ы)"
                  />
                  <ReactSelect
                    star={true}
                    menuPlacement="top"
                    text="Страна"
                    placeholder="Выберите Страну"
                    defaultValue={{
                      label: this.state.countries[this.state.index].name,
                      value: this.state.countries[this.state.index].id,
                    }}
                    onChange={this.changeCountryId(
                      this.validateUsersDataLength("countryId", 1)
                    )}
                    options={getOptions(this.state.countries)}
                    onBlur={this.isTouched(
                      "countryId",
                      this.validateUsersDataLength("countryId", 1)
                    )}
                    required={this.isRequired("countryId")()}
                    validationMessage="Поле не должно быть пустым"
                  />
                  <div className="active-header">
                    <Button
                      disabled={this.state.disabled}
                      variant={buttonType}
                      onClick={this.isSendRequest(
                        `/api/v1/common/regions/${this.id}`,
                        this.state.body
                      )}
                    >
                      Сохранить
                    </Button>
                    {this.state.incorrectText && (
                      <span>{this.state.errorText}</span>
                    )}
                    {this.state.successText && (
                      <span>Данные региона изменены</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
