import React from "react";
import { connect } from "react-redux";
import Input from "../Components/input/Input";
import Button from "../Components/Button/Button";
import { getMe } from "../redux/actions/getMe";
import API from "../Utils/API";
import "./index.scss";
import { validationOnUsersName } from "../helper/validateHelper/validateHelper";
import PasswordInput from "../Components/input/passwordInput";
class SignInPage extends React.Component {
  state = {
    body: {
      name: "",
      password: "",
    },
    name: {
      name: "",
      touched: false,
    },
    password: {
      password: "",
      touched: false,
    },
    type: false,
    disabled: false,
    incorrectText: false,
  };
  sendRequest = async (username, password) => {
    try {
      this.setState({ disabled: true });
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      return await API().post("/api/v1/login", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (e) {
      this.setState({ ...this.state, incorrectText: true, disabled: false });
    }
  };
  goToMainPage = () => {
    this.props.getMe();
  };
  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.signIn()
    }
  }
  changeName = (data, foo) => (event) => {
    this.setState(
      {
        body: { ...this.state.body, [data]: event.target.value },
        incorrectText: false,
        disabled: false,
      },
      () => {
        foo();
      }
    );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(this.state.body[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  isDisabledButton = () => {
    const state = this.state;
    return state.name.name === false && state.password.password === false;
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    let inputType = "password";
    if (this.state.type === true) {
      inputType = "text";
    }
    return (
      <div className="container">
        <div className="logo__img">
          <span className="h2-text">GoldRiver</span>
        </div>

        <form>
          <div className="dws__input">
            <Input
              onKeyPress={this.handleKeyPress}
              text="Логин"
              type="text"
              placeholder="Введите логин"
              onChange={this.changeName(
                "name",
                this.validateUsersDataLength("name", 3)
              )}
              onBlur={this.isTouched(
                "name",
                this.validateUsersDataLength("name", 3)
              )}
              required={this.isRequired("name")()}
              validationMessage="Длина должна быть более 2 символов"
            />
            <PasswordInput
              onKeyPress={this.handleKeyPress}
              text="Пароль"
              type={inputType}
              placeholder="Введите пароль"
              onChange={this.changeName(
                "password",
                this.validateUsersDataLength("password", 6)
              )}
              onBlur={this.isTouched(
                "password",
                this.validateUsersDataLength("password", 6)
              )}
              required={this.isRequired("password")()}
              validationMessage="Пароль должен быть более 5 символов"
            />
            {this.state.incorrectText && (
              <div className="not__true">
                <span className="color:red">Неправильно ввели данные</span>{" "}
              </div>
            )}
            <Button
              variant={buttonType}
              disabled={this.state.disabled}
              type="button"
              onClick={this.signIn}
            >
              Войти
            </Button>
          </div>
        </form>
      </div>
    );
  }
  signIn = () => {
    this.sendRequest(this.state.body.name, this.state.body.password).then(
      () => {
        this.goToMainPage();
      }
    );
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getMe: () => dispatch(getMe()),
  };
};
export default connect(null, mapDispatchToProps)(SignInPage);
