import React from "react";
import { ROUTES } from "./Routes";
import { connect } from "react-redux";
import { getMe } from "./redux/actions/getMe";
import { renderRoutes } from "react-router-config";
import NavBar from "./container/MainPage/navbar/NavBar";
import Loader from "./Components/loader/loader";
import "./App.scss"
import Footer from "./container/MainPage/footer/footer";


class App extends React.Component {
  state = {
    isLoading: 0
}
  getUserData = async () => this.props.getMe();

  componentDidMount() {
    this.getUserData().then(() => this.setState({...this.state, isLoading: 1}));
  }
  renderRoutes = () => {
    const isLogged = !!this.props.meReducer.data;
    return (
      ROUTES.filter((route) => (route.check ? route.check(isLogged) : true)) ||
      []
    );
  };
  render() {
if(this.state.isLoading === 0){
  return <div className="loader-container"><Loader className="center" width="50px" height="50px"/></div>
}
    return (
      <>
        {!!this.props.meReducer.data && <NavBar data={this.props.meReducer.data} />}
        <div className="App">{renderRoutes(this.renderRoutes())}</div>
        <Footer/>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMe: () => dispatch(getMe()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
