import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import API from "../../../Utils/API";
import ReactSelect from "../../../Components/react-select/react-select";
import "./addPartner.scss";
import {
  getOptions,
  getRequest,
} from "../../../helper/forCountries/forCountries";
import {
  validationForNumeric,
  validationOnEmail,
  validationOnPassword,
  validationOnUsersName,
} from "../../../helper/validateHelper/validateHelper";
import ReactDatePicker from "../../../Components/react-date-picker/reactDatePicker";
import { Link } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DEFAULT_STATE_FOR_ADD_PARTNER } from "../../../helper/forAddPartner/helperForAddPartner";

export default class AddPartner extends React.Component {
  state = {
    ...DEFAULT_STATE_FOR_ADD_PARTNER,
    regionId: null,
    cityId: null,
    searchMentor: {
      pageRequest: {
        page: 1,
        limit: 5,
      },
      filter: {
        searchParameter: null,
      },
    },
    searchProposer: {
      pageRequest: {
        page: 1,
        limit: 5,
      },
      filter: {
        searchParameter: null,
      },
    },
    successText: false,
    incorrectText: false,
    disabled: false,
    errorText: "",
  };
  componentDidMount() {
    getRequest("/api/v1/common/countries")
      .then((data) => this.setState({ ...this.state, country: data.data.data }))
      .catch((e) => console.error("error: ", e));
  }
  dateChange = (date) => {
    this.setState(
      {
        ...this.state,
        body: {
          ...this.state.body,
          passport: {
            ...this.state.body.passport,
            issuedDate: new Date(new Date(date).getTime() + 86399999),
          },
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  citiesOnRegion = async (id) => {
    try {
      return await API().get(`/api/v1/common/cities/find-by-region/${id}`);
    } catch (e) {
      console.error("error: ", e);
    }
  };
  regionsOnCountry = async (id) => {
    try {
      return await API().get(`/api/v1/common/regions/find-by-country/${id}`);
    } catch (e) {
      console.error("error: ", e);
    }
  };
  sendRequest = async () => {
    try {
      this.setState({ disabled: true });
      await API().post("/api/v1/admin/partners", this.state.body, {
        headers: { "Content-Type": "application/json" },
      });
      this.setState({
        ...DEFAULT_STATE_FOR_ADD_PARTNER,
        successText: true,
        incorrectText: false,
        disabled: false,
      });
    } catch (e) {
      this.setState({
        successText: false,
        incorrectText: true,
        disabled: false,
        errorText: e.response.data.message
      });
    }
  };
  changeUserData = (data, length, type) => (e) => {
    return this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (type === "email") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationOnEmail(this.state.body.email),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        } else if (type === "string") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationOnUsersName(this.state.body[data], length),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        } else if (type === "number") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationForNumeric(this.state.body[data]),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        }
      }
    );
  };
  changePassportData = (data, length, type) => (e) => {
    this.setState(
      {
        ...this.state,
        body: {
          ...this.state.body,
          passport: { ...this.state.body.passport, [data]: e.target.value },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (type === "string") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationOnUsersName(
                  this.state.body.passport[data],
                  length
                ),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        } else if (type === "number") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationForNumeric(this.state.body.passport[data]),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        }
      }
    );
  };
  changeAddressData = (data, length) => (e) => {
    this.setState(
      {
        body: {
          ...this.state.body,
          address: { ...this.state.body.address, [data]: e.target.value },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        this.setState(
          {
            ...this.state,
            [data]: {
              ...this.state[data],
              [data]: validationOnUsersName(
                this.state.body.address[data],
                length
              ),
            },
          },
          () =>
            this.setState({
              ...this.state,
              disabled: !this.isDisabledButton(),
            })
        );
      }
    );
  };
  changeCountryId = (e) => {
    this.setState(
      {
        ...this.state,
        regionId: null,
        cityId: null,
        region: [],
        cities: [],
        body: {
          ...this.state.body,
          address: {
            ...this.state.body.address,
            countryId: e ? e.value : null,
            regionId: null,
            cityId: null,
          },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (this.state.body.address.countryId) {
          this.regionsOnCountry(this.state.body.address.countryId).then(
            (data) =>
              this.setState({
                ...this.state,
                region: data.data.data,
              })
          );
          this.setState(
            {
              ...this.state,
              countryId: {
                ...this.state.countryId,
                countryId: validationForNumeric(
                  this.state.body.address.countryId
                ),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        }
      }
    );
  };
  changeRegionId = (e) => {
    this.setState(
      {
        ...this.state,
        cityId: null,
        cities: [],
        regionId: { value: e ? e.value : null, label: e ? e.label : null },
        body: {
          ...this.state.body,
          address: {
            ...this.state.body.address,
            regionId: e ? e.value : null,
            cityId: null,
          },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (this.state.body.address.regionId) {
          this.citiesOnRegion(this.state.body.address.regionId).then((data) =>
            this.setState({
              ...this.state,
              cities: data.data.data,
            })
          );
          this.setState(
            {
              ...this.state,
              regionId: {
                ...this.state.regionId,
                regionId: validationForNumeric(
                  this.state.body.address.regionId
                ),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        }
      }
    );
  };
  changeCityId = (e) => {
    this.setState({
      ...this.state,
      cityId: { value: e ? e.value : null, label: e ? e.label : null },
      body: {
        ...this.state.body,
        address: { ...this.state.body.address, cityId: e ? e.value : null },
      },
      incorrectText: false,
      successText: false,
    });
  };
  handleInputChange = (e, { action }) => {
    if (action === "input-change") {
      this.setState(
        {
          ...this.state,
          searchProposer: {
            pageRequest: {
              page: 1,
              limit: 5,
            },
            filter: {
              username: "",
            },
          },
          body: { ...this.state.body, mentorId: null, proposerId: null },
          searchMentor: {
            pageRequest: {
              page: 1,
              limit: 5,
            },
            filter: {
              searchParameter: e,
            },
          },
        },
        async () => {
          try {
            const response = await API().post(
              "/api/v1/admin/partners/search",
              this.state.searchMentor,
              {
                headers: { "Content-Type": "application/json" },
              }
            );
            this.setState(
              { ...this.state, mentors: response.data.content },
              () => {
                this.options("mentors");
              }
            );
          } catch (e) {
            console.error(e);
          }
        }
      );
    }
  };
  chooseProposer = (e, { action }) => {
    if (action === "input-change") {
      this.setState(
        {
          ...this.state,
          body: { ...this.state.body, proposerId: null },
          searchProposer: {
            pageRequest: {
              page: 1,
              limit: 5,
            },
            filter: {
              searchParameter: e,
            },
          },
        },
        async () => {
          try {
            const response = await API().post(
              `/api/v1/admin/partners/${this.state.body.mentorId}/search-parents`,
              this.state.searchProposer,
              {
                headers: { "Content-Type": "application/json" },
              }
            );
            this.setState(
              { ...this.state, proposers: response.data.content },
              () => {
                this.options("proposers");
              }
            );
          } catch (e) {
            console.error(e);
          }
        }
      );
    }
  };
  changeProposerId = (data, inputValue) => (e) => {
    this.setState(
      {
        ...this.state,
        [inputValue]: {
          ...this.state[inputValue],
          filter: {
            searchParameter: e ? e.label : null,
          },
        },
        body: { ...this.state.body, [data]: e ? e.value : "" },
      },
      () => {
        this.setState(
          {
            ...this.state,
            [data]: {
              ...this.state[data],
              [data]: validationForNumeric(this.state.body[data]),
            },
          },
          () =>
            this.setState({
              ...this.state,
              disabled: !this.isDisabledButton(),
            })
        );
      }
    );
  };
  options = (data) => {
    return this.state[data].map((item) => {
      return {
        label: `${item.surname} ${item.firstName} ${item.code}`,
        value: item.id,
      };
    });
  };
  validateLength = (data, location) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnPassword(location[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateUsersDataLength = (data, location, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(location[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateEmail = () => {
    this.setState(
      {
        ...this.state,
        email: {
          ...this.state.email,
          email: validationOnEmail(this.state.body.email),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateNumber = (data, location) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationForNumeric(location[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isDisabledButton = () => {
    const state = this.state;
    return (
      state.code.code === false &&
      state.password.password === false &&
      state.firstName.firstName === false &&
      state.surname.surname === false &&
      state.username.username === false &&
      state.mentorId.mentorId === false &&
      state.proposerId.proposerId === false
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <div className="partner-page">
        <div className="active-header">
          <h2>Добавить партнера</h2>
          <Link to="/users/all-partners">
            <Button variant="blue">
              <FontAwesomeIcon icon={faArrowLeft} />
              <span> Назад </span>
            </Button>
          </Link>
        </div>
        <div className="categories_bar">
          <div className="active-block">
            <div className="add-partner-flex">
              <div className="input-height">
                <Input
                  star={true}
                  text="Код"
                  placeholder="Введите Код"
                  value={this.state.body.code}
                  onBlur={this.isTouched(
                    "username",
                    this.validateUsersDataLength("code", this.state.body, 1)
                  )}
                  onChange={this.changeUserData("code", 1, "string")}
                  required={this.isRequired("code")()}
                  validationMessage="Укажите код"
                />
              </div>
              <div className="input-height">
                <Input
                  star={true}
                  text="Логин"
                  placeholder="Введите логин"
                  value={this.state.body.username}
                  onBlur={this.isTouched(
                    "username",
                    this.validateUsersDataLength("username", this.state.body, 3)
                  )}
                  onChange={this.changeUserData("username", 3, "string")}
                  required={this.isRequired("username")()}
                  validationMessage="Длина должна быть не меньше 3 символов"
                />
              </div>
              <div className="input-height">
                <Input
                  star={true}
                  text="Пароль"
                  value={this.state.body.password}
                  onChange={this.changeUserData("password", 6, "string")}
                  onBlur={this.isTouched(
                    "password",
                    this.validateLength("password", this.state.body)
                  )}
                  required={this.isRequired("password")()}
                  validationMessage="Длина пароля не должно быть меньше 6 символов"
                />
              </div>
              <div className="input-height">
                <Input
                  text="Email"
                  value={this.state.body.email}
                  onChange={this.changeUserData("email", 3, "string")}
                />
              </div>
            </div>
            <div className="add-partner-flex">
              <div className="input-height">
                <Input
                  star={true}
                  text="Фамилия"
                  value={this.state.body.surname}
                  onChange={this.changeUserData("surname", 3, "string")}
                  onBlur={this.isTouched(
                    "surname",
                    this.validateUsersDataLength("surname", this.state.body, 3)
                  )}
                  required={this.isRequired("surname")()}
                  validationMessage="Длина фамилии не должно быть меньше 3 букв"
                />
              </div>
              <div className="input-height">
                <Input
                  star={true}
                  text="Имя"
                  value={this.state.body.firstName}
                  onChange={this.changeUserData("firstName", 3, "string")}
                  onBlur={this.isTouched(
                    "firstName",
                    this.validateUsersDataLength(
                      "firstName",
                      this.state.body,
                      3
                    )
                  )}
                  required={this.isRequired("firstName")()}
                  validationMessage="Длина имени не должно быть меньше 3 букв"
                />
              </div>
              <div className="input-height">
                <Input
                  text="Отчество"
                  value={this.state.body.patronymic}
                  onChange={this.changeUserData("patronymic", 3, "string")}
                />
              </div>
              <div className="input-height">
                <Input
                  text="Название банка"
                  value={this.state.body.bankName}
                  onChange={this.changeUserData("bankName", 3, "string")}
                />
              </div>
            </div>
            <div className="add-partner-flex">
              <div className="input-height">
                <Input
                  type="number"
                  text="Номер карты"
                  value={this.state.body.bankCardNumber}
                  onChange={this.changeUserData("bankCardNumber", 1, "number")}
                />
              </div>
              <div className="input-height">
                <Input
                  text="Номер паспорта"
                  value={this.state.body.passport.code}
                  onChange={this.changePassportData("code", 3, "string")}
                />
              </div>
              <div className="input-height">
                <Input
                  text="ИНН паспорта"
                  value={this.state.body.passport.itn}
                  onChange={this.changePassportData("itn", 3, "number")}
                />
              </div>
              <div className="input-height">
                <Input
                  text="Орган выдавший паспорт"
                  value={this.state.body.passport.issuedBy}
                  onChange={this.changePassportData("issuedBy", 6, "string")}
                />
              </div>
            </div>
            <div className="add-partner-flex">
              <div className="input-height">
                <ReactDatePicker
                  variant="basic"
                  text="Дата выдачи паспорта"
                  selected={this.state.body.passport.issuedDate}
                  onChange={this.dateChange}
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                />
              </div>
              <div className="input-height-2">
                <ReactSelect
                  value={this.state.body.mentorId}
                  inputValue={this.state.searchMentor.filter.searchParameter}
                  filterOption={(option) => option}
                  star={true}
                  menuPlacement="top"
                  placeholder="Найдите наставника..."
                  text="Выберите наставника"
                  onInputChange={this.handleInputChange}
                  onChange={this.changeProposerId("mentorId", "searchMentor")}
                  options={this.options("mentors")}
                  onBlur={this.isTouched(
                    "mentorId",
                    this.validateNumber("mentorId", this.state.body)
                  )}
                  required={this.isRequired("mentorId")()}
                  validationMessage="наставник не выбран"
                />
              </div>
              <div className="input-height-2">
                <ReactSelect
                  value={this.state.body.proposerId}
                  inputValue={this.state.searchProposer.filter.searchParameter}
                  filterOption={(option) => option}
                  star={true}
                  menuPlacement="top"
                  placeholder="Найдите рекомендателя..."
                  text="Выберите рекомендателя"
                  onInputChange={this.chooseProposer}
                  onChange={this.changeProposerId(
                    "proposerId",
                    "searchProposer"
                  )}
                  options={this.options("proposers")}
                  onBlur={this.isTouched(
                    "proposerId",
                    this.validateNumber("proposerId", this.state.body)
                  )}
                  required={this.isRequired("proposerId")()}
                  validationMessage="Рекомендатель не выбран"
                />
              </div>
              <div className="input-height">
                <Input
                  text="Название улицы"
                  value={this.state.body.address.street}
                  onChange={this.changeAddressData("street", 3)}
                />
              </div>
            </div>
            <div className="add-partner-flex">
              <div className="input-height-2">
                <ReactSelect
                  text="Страна"
                  placeholder="Выберите страну"
                  onChange={this.changeCountryId}
                  options={getOptions(this.state.country)}
                />
              </div>
              <div className="input-height-2">
                <ReactSelect
                  value={this.state.regionId}
                  text="Регион"
                  placeholder="Выберите регион..."
                  onChange={this.changeRegionId}
                  options={getOptions(this.state.region)}
                />
              </div>
              <div className="input-height-2">
                <ReactSelect
                  value={this.state.cityId}
                  text="Город"
                  placeholder="Выберите город..."
                  onChange={this.changeCityId}
                  options={getOptions(this.state.cities)}
                />
              </div>
              <div className="input-height">
                <Input
                  text="Номер дома"
                  value={this.state.body.address.house}
                  onChange={this.changeAddressData("house", 1)}
                />
              </div>
            </div>
          </div>
          <div className="btn-text-center">
            <Button
              variant={buttonType}
              onClick={this.sendRequest}
              disabled={this.state.disabled}
            >
              Добавить
            </Button>
            {this.state.incorrectText && (
              <h3 className="add-wrong">
                {this.state.errorText}
              </h3>
            )}
            {this.state.successText && (
              <h3 className="add-correct">Успешно зарегистрировано</h3>
            )}
          </div>
        </div>
      </div>
    );
  }
}
