export const validationOnPassword = (password) => {
  return password.length < 6;
};
export const validationOnEmail = (email) => {
  const re = /^\S+@\S+\.\S+$/;
  return !re.test(email);
};
export const validationOnUsersName = (firstName, number) => {
  return firstName.length < number;
};
export const validationForNumeric = (number) => {
  const reg = /^\d+$/;
  return !reg.test(number);
};
export const validationForQuantity = (number) => {
  return number < 1
}