import React from "react";
import { getRequest } from "../../helper/forCountries/forCountries";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/loader/loader";
import { faPen, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {sortFn} from "../../helper/sort";

export default class CustomCycleSettings extends React.Component {
  state = {
    changeModal: false,
    addModal: false,
    loader: true,
    cycles: [],
    id: "",
    cycle: "",
    partnersPerCycle: "",
    bonusOnComplete: "",
  };
  componentDidMount() {
this.getRequest()
  }
  getRequest = () => {
    getRequest("/api/v1/cycles/settings/custom/find-all").then((data) =>
      this.setState({ ...this.state, cycles: sortFn(data.data.data), loader: false })
    ).catch(e => console.log(e));
  }
  cycleList = () => {
    return this.state.cycles.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.cycle}</td>
          <td>{item.partnersPerCycle}</td>
          <td>{item.bonusOnComplete} у.е</td>
          <td>
            <Button
              variant="secondary"
              onClick={this.openModal(
                "changeModal",
                item.id,
                item.cycle,
                item.partnersPerCycle,
                item.bonusOnComplete,
                this.openChangeModal
              )}
            >
              <FontAwesomeIcon icon={faPen} /> Изменить
            </Button>
          </td>
        </tr>
      );
    });
  };
  openModal = (
    data,
    id,
    cycle,
    partnersPerCycle,
    bonusOnComplete,
    fn
  ) => () => {
    this.setState(
      {
        ...this.state,
        [data]: true,
        id,
        cycle,
        partnersPerCycle,
        bonusOnComplete,
      },
      () => fn()
    );
  };
  render() {
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            <div className="categories_bar">
              <div className="active-header">
                <h3>Всего {this.state.cycles.length}</h3>
                <Button variant="blue" onClick={this.openAddModal}>
                  <FontAwesomeIcon icon={faSync} /> Добавить цикл
                </Button>
              </div>
            </div>
            <div className="table-wrapper">
              <table className="fl-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Номер цикла</th>
                    <th>Количество партнеров на каждом цикле</th>
                    <th>Бонусы на каждом цикле</th>
                    <th>Действия по циклу</th>
                  </tr>
                </thead>
                <tbody>{this.cycleList()}</tbody>
              </table>
            </div>
          </>
        )}
      </>
    );
  }
}
