import Button from "../../Components/Button/Button";
import React from "react";

export const getPages = (users, page, fn) => {
  if (users) {
    let allPages = users.totalPages;
    let pages = [];
    for (let i = 1; i <= allPages; i++) {
      pages.push(i);
    }
    const activePage = page;
    return (<> {allPages > 1 &&
      <>
        {activePage > 3 && <Button variant="blue" onClick={fn(1)}>{"<<"}</Button>}
        {pages.indexOf(activePage - 2) !== -1 && (
          <Button variant="blue" onClick={fn(activePage - 2)}>
            {activePage - 2}
          </Button>
        )}
        {pages.indexOf(activePage - 1) !== -1 && (
          <Button variant="blue" onClick={fn(activePage - 1)}>
            {activePage - 1}
          </Button>
        )}
        <Button disabled={true} >
          {activePage}
        </Button>
        {pages.indexOf(activePage + 1) !== -1 && (
          <Button variant="blue" onClick={fn(activePage + 1)}>
            {activePage + 1}
          </Button>
        )}
        {pages.indexOf(activePage + 2) !== -1 && (
          <Button variant="blue" onClick={fn(activePage + 2)}>
            {activePage + 2}
          </Button>
        )}
        {activePage < pages.length - 2 && <Button variant="blue" onClick={fn(pages.length)}>{">>"}</Button>}
      </>
  }
  </>
    );
  }
};
