import API from "../../Utils/API";
API().defaults.withCredentials = true;

export const getMe = () => {
  return async (dispatch) => {
    try {
      const response = await API().get("/api/v1/accounts/me");
      dispatch({
        type: "ME_DATA",
        data: response.data,
      });
    } catch (error) {
      dispatch({
        type: "ME_ERROR",
        data: error.message || error,
      });
    }
  };
};
