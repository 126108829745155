import React from "react";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import {
  getOptions,
  getRequest,
  sendRequestToEdit,
} from "../../../helper/forCountries/forCountries";
import ReactSelect from "../../../Components/react-select/react-select";
import {
  validationForNumeric,
  validationOnUsersName,
} from "../../../helper/validateHelper/validateHelper";
import Loader from "../../../Components/loader/loader";

export default class EditCity extends React.Component {
  id = this.props.id;
  state = {
    loader: true,
    index: null,
    body: {
      name: "",
      key: "",
      regionId: "",
    },
    disabled: true,
    name: {
      name: false,
      touched: false,
    },
    key: {
      key: false,
      touched: false,
    },
    regionId: {
      regionId: false,
      touched: false,
    },
    countryId: "",
    regions: [],
    incorrectText: false,
    successText: false,
    errorText: "",
  };
  componentDidMount() {
    getRequest(`/api/v1/common/cities/${this.id}`).then((data) =>
      this.setState(
        {
          ...this.state,
          body: {
            ...this.state.body,
            name: data.data.name,
            key: data.data.id,
            regionId: data.data.region.id,
          },
          countryId: data.data.region.country.id,
        },
        () => {
          getRequest(
            `/api/v1/common/regions/find-by-country/${this.state.countryId}`
          ).then((data) =>
            this.setState({ ...this.state, regions: data.data.data }, () =>
              this.setState({
                ...this.state,
                index: this.setSelectDefaultValue(),
                loader: false,
              })
            )
          );
        }
      )
    );
  }
  setSelectDefaultValue = () => {
    const arr = [];
    this.state.regions.map((item) => arr.push(item.id));
    return arr.indexOf(this.state.body.regionId);
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  isSendRequest = (url, body) => () => {
    return sendRequestToEdit(url, body)
      .then(() => this.setState({ ...this.state, successText: true }))
      .catch((e) =>
        this.setState({
          ...this.state,
          incorrectText: true,
          errorText: e.response.data.message,
        })
      );
  };
  changeRegionId = (foo) => (e) => {
    this.setState(
      {
        ...this.state,
        body: {
          ...this.state.body,
          regionId: e ? e.value : "",
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(this.state.body[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateForNumeric = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationForNumeric(this.state.body[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  isDisabledButton = () => {
    const state = this.state;
    return (
      state.name.name === false &&
      state.key.key === false &&
      state.regionId.regionId === false
    );
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            {this.state.index !== null && (
              <div className="modal-page">
                <span>Изменить данные города</span>
                <div className="categories_bar">
                  <Input
                    star={true}
                    text="Название города"
                    value={this.state.body.name}
                    onChange={this.changeUserData(
                      "name",
                      this.validateUsersDataLength("name", 2)
                    )}
                    onBlur={this.isTouched(
                      "name",
                      this.validateUsersDataLength("name", 2)
                    )}
                    required={this.isRequired("name")()}
                    validationMessage="Поле не должно быть пустым"
                  />
                  <Input
                    star={true}
                    text="ID города"
                    value={this.state.body.key}
                    onChange={this.changeUserData(
                      "key",
                      this.validateForNumeric("key")
                    )}
                    onBlur={this.isTouched(
                      "key",
                      this.validateForNumeric("key")
                    )}
                    required={this.isRequired("key")()}
                    validationMessage="Поле не должно быть пустым и содержать только цифру(ы)"
                  />
                  <ReactSelect
                    star={true}
                    menuPlacement="top"
                    text="Регион"
                    placeholder="Выберите регион"
                    defaultValue={{
                      label: this.state.regions[this.state.index].name,
                      value: this.state.regions[this.state.index].id,
                    }}
                    onChange={this.changeRegionId(
                      this.validateUsersDataLength("regionId", 1)
                    )}
                    options={getOptions(this.state.regions)}
                    onBlur={this.isTouched(
                      "regionId",
                      this.validateUsersDataLength("regionId", 1)
                    )}
                    required={this.isRequired("regionId")()}
                    validationMessage="Поле не должно быть пустым"
                  />
                  <div className="active-header">
                    <Button
                      disabled={this.state.disabled}
                      variant={buttonType}
                      onClick={this.isSendRequest(
                        `/api/v1/common/cities/${this.id}`,
                        this.state.body
                      )}
                    >
                      сохранить
                    </Button>
                    {this.state.incorrectText && (
                      <span className="error-message">
                        {this.state.errorText}
                      </span>
                    )}
                    {this.state.successText && (
                      <span className="success-message">Успешно изменен</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
