import React from "react";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import { sendRequest } from "../../../helper/forCountries/forCountries";

const DEFAULT_FORM_STATE = {
  name: {
    value: "",
    touched: false,
    isValid: false,
  },
  key: {
    value: "",
    touched: false,
    isValid: false,
  },
  serverError: false,
  success: false,
  errorText: "",
};

const FORM_KEYS = {
  Name: "name",
  Key: "key",
};

export default class AddCountry extends React.Component {
  state = { ...DEFAULT_FORM_STATE };

  onChangeForm = (key) => (event) => {
    this.setState({
      ...this.state,
      [key]: {
        ...this.state[key],
        value: key === FORM_KEYS.Key ? +event.target.value : event.target.value,
        isValid: this.handleValidateStrategy(key, event.target.value),
      },
      serverError: false,
      success: false,
    });
  };

  handleValidateStrategy = (key, value) => {
    const obj = {
      name: this.validateName,
      key: this.validateKey,
    };
    return obj[key](value);
  };

  validateName = (value) => value.length >= 2;

  validateKey = (value) => !!value.length;

  isSendRequest = (url) => () => {
    const { name, key } = this.state;
    const params = { name: name.value, key: key.value };
    return sendRequest(url, params)
      .then(() => {
        this.setState({ ...DEFAULT_FORM_STATE, success: true });
      })
      .catch((e) =>
        this.setState({ ...this.state, success: false, serverError: true,
          errorText: e.response.data.message})
      );
  };

  setTouched = (key) => () => {
    this.setState({
      ...this.state,
      [key]: {
        ...this.state[key],
        touched: true,
      },
    });
  };

  render() {
    return (
      <div className="modal-page">
        <span>Добавить страну</span>
        <div className="categories_bar">
        <Input
          text="Название cтраны"
          star={true}
          value={this.state.name.value}
          onChange={this.onChangeForm(FORM_KEYS.Name)}
          onBlur={this.setTouched(FORM_KEYS.Name)}
          required={this.state.name.touched && !this.state.name.isValid}
          validationMessage="Поле не должно быть пустым"
        />
        <Input
          star={true}
          text="ID страны"
          value={this.state.key.value}
          onChange={this.onChangeForm(FORM_KEYS.Key)}
          onBlur={this.setTouched(FORM_KEYS.Key)}
          required={this.state.key.touched && !this.state.key.isValid}
          validationMessage="Поле не должно быть пустым и содержать только цифру(ы)"
        />
        <div className="active-header">
        <Button
          disabled={!this.state.name.isValid || !this.state.key.isValid}
          variant="secondary"
          onClick={this.isSendRequest("/api/v1/common/countries")}
        >
          Добавить
        </Button>
        {this.state.serverError && (
          <span className="error-message">{this.state.errorText}</span>
        )}
        {this.state.success && <span className="success-message">Страна успешно добавлена</span>}
        </div>
        </div>
      </div>
    );
  }
}

