import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import API from "../../../Utils/API";
import ReactSelect from "../../../Components/react-select/react-select";
import "./EditPartner.scss";
import {
  getOptions,
  getRequest,
} from "../../../helper/forCountries/forCountries";
import {
  validationForNumeric,
  validationOnEmail,
  validationOnPassword,
  validationOnUsersName,
} from "../../../helper/validateHelper/validateHelper";
import Loader from "../../../Components/loader/loader";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ReactDatePicker from "../../../Components/react-date-picker/reactDatePicker";
import { HELPER_FOR_EDIT_PARTNER } from "../../../helper/forAddPartner/helperForAddPartner";
import Modal from "react-modal";
import ChangePassword from "../../changePassword/changePassword";

class EditPartner extends React.Component {
  state = {
    ...HELPER_FOR_EDIT_PARTNER,
    regionId: null,
    cityId: null,
    modal: false,
    id: this.props.match.params.id,
    successText: false,
    incorrectText: false,
    disabled: false,
    errorText: "",
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    getRequest(`/api/v1/admin/partners/${id}`)
      .then((data) =>
        this.setState({
          ...this.state,
          id: this.props.match.params.id,
          body: {
            ...this.state.body,
            passport: {
              code: data.data.passport.code,
              issuedBy: data.data.passport.issuedBy,
              itn: data.data.passport.itn,
              issuedDate: new Date(data.data.passport.issuedDate),
            },
            address: {
              ...this.state.body.address,
              street: data.data.address.street,
              house: data.data.address.house,
            },
            code: data.data.code,
            username: data.data.username,
            surname: data.data.surname,
            firstName: data.data.firstName,
            bankName: data.data.bankName,
            bankCardNumber: data.data.bankCardNumber,
            email: data.data.email,
            patronymic: data.data.patronymic,
          },
        })
      )
      .catch((e) => console.error("error: ", e));
    getRequest("/api/v1/common/countries")
      .then((data) =>
        this.setState({ ...this.state, loader: false, country: data.data.data })
      )
      .catch((e) => console.error("error: ", e));
  }
  dateChange = (date) => {
    this.setState(
      {
        ...this.state,
        body: {
          ...this.state.body,
          passport: {
            ...this.state.body.passport,
            issuedDate: new Date(new Date(date).getTime() + 86399999),
          },
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  citiesOnRegion = async (id) => {
    try {
      return await API().get(`/api/v1/common/cities/find-by-region/${id}`);
    } catch (e) {
      console.error("error: ", e);
    }
  };
  regionsOnCountry = async (id) => {
    try {
      return await API().get(`/api/v1/common/regions/find-by-country/${id}`);
    } catch (e) {
      console.error("error: ", e);
    }
  };
  sendRequest = async () => {
    const id = this.state.id;
    try {
      this.setState({ disabled: true });
      await API().put(`/api/v1/admin/partners/${id}`, this.state.body, {
        headers: { "Content-Type": "application/json" },
      });
      this.setState({
        ...this.setState({
          ...this.state,
          successText: true,
          password: true,
          body: { ...this.state.body, password: "" },
        }),
      });
    } catch (e) {
      this.setState({
        ...this.state,
        successText: false,
        incorrectText: true,
        disabled: false,
        errorText: e.response.data.message,
      });
    }
  };
  changeUserData = (data, length, type) => (e) => {
    return this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (type === "email") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationOnEmail(this.state.body.email),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        } else if (type === "string") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationOnUsersName(this.state.body[data], length),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        } else if (type === "number") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationForNumeric(this.state.body[data]),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        }
      }
    );
  };
  changePassportData = (data, length, type) => (e) => {
    this.setState(
      {
        ...this.state,
        body: {
          ...this.state.body,
          passport: { ...this.state.body.passport, [data]: e.target.value },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (type === "string") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationOnUsersName(
                  this.state.body.passport[data],
                  length
                ),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        } else if (type === "number") {
          this.setState(
            {
              ...this.state,
              [data]: {
                ...this.state[data],
                [data]: validationForNumeric(this.state.body.passport[data]),
              },
            },
            () =>
              this.setState({
                ...this.state,
                disabled: !this.isDisabledButton(),
              })
          );
        }
      }
    );
  };
  changeAddressData = (data, length) => (e) => {
    this.setState(
      {
        body: {
          ...this.state.body,
          address: { ...this.state.body.address, [data]: e.target.value },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        this.setState(
          {
            ...this.state,
            [data]: {
              ...this.state[data],
              [data]: validationOnUsersName(
                this.state.body.address[data],
                length
              ),
            },
          },
          () =>
            this.setState({
              ...this.state,
              disabled: !this.isDisabledButton(),
            })
        );
      }
    );
  };
  changeCountryId = (e) => {
    this.setState(
      {
        ...this.state,
        regionId: null,
        cityId: null,
        region: [],
        cities: [],
        body: {
          ...this.state.body,
          address: {
            ...this.state.body.address,
            countryId: e ? e.value : null,
            regionId: null,
            cityId: null,
          },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (this.state.body.address.countryId) {
          this.regionsOnCountry(this.state.body.address.countryId).then(
            (data) =>
              this.setState({
                ...this.state,
                region: data.data.data,
              })
          );
        }
      }
    );
  };
  changeRegionId = (e) => {
    this.setState(
      {
        ...this.state,
        cityId: null,
        cities: [],
        regionId: { value: e ? e.value : null, label: e ? e.label : null },
        body: {
          ...this.state.body,
          address: {
            ...this.state.body.address,
            regionId: e ? e.value : null,
            cityId: null,
          },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (this.state.body.address.regionId) {
          this.citiesOnRegion(this.state.body.address.regionId).then((data) =>
            this.setState({
              ...this.state,
              cities: data.data.data,
            })
          );
        }
      }
    );
  };
  changeCityId = (e) => {
    this.setState({
      ...this.state,
      cityId: { value: e ? e.value : null, label: e ? e.label : null },
      body: {
        ...this.state.body,
        address: { ...this.state.body.address, cityId: e ? e.value : null },
      },
      incorrectText: false,
      successText: false,
    });
  };
  validateLength = (data, location) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnPassword(location[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateUsersDataLength = (data, location, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(location[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateEmail = () => {
    this.setState(
      {
        ...this.state,
        email: {
          ...this.state.email,
          email: validationOnEmail(this.state.body.email),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateNumber = (data, location) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationForNumeric(location[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isDisabledButton = () => {
    const state = this.state;
    return (
      state.firstName.firstName === false &&
      state.surname.surname === false &&
      state.username.username === false &&
      state.code.code === false
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  openModal = () => {
    this.setState({ ...this.state, modal: true });
  };
  closeModal = () => {
    this.setState({ ...this.state, modal: false });
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <div className="partner-page">
            <div className="active-header">
              <div>
                <h2>Изменить партнера</h2>
                <Button variant="secondary" onClick={this.openModal}>
                  Изменить пароль
                </Button>
              </div>
              <Link to="/users/all-partners">
                <Button variant="blue">
                  <span>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    {"  "}
                    Назад
                  </span>
                </Button>
              </Link>
            </div>
            <div className="categories_bar">
              <div className="active-block">
                <div className="add-partner-flex">
                  <div className="input-height">
                    <Input
                      star={true}
                      text="Код"
                      placeholder="Введите код"
                      value={this.state.body.code}
                      onBlur={this.isTouched(
                        "code",
                        this.validateUsersDataLength("code", this.state.body, 3)
                      )}
                      onChange={this.changeUserData("code", 1, "string")}
                      required={this.isRequired("code")()}
                      validationMessage="Введите код"
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      star={true}
                      text="Логин"
                      placeholder="Введите логин"
                      value={this.state.body.username}
                      onBlur={this.isTouched(
                        "username",
                        this.validateUsersDataLength(
                          "username",
                          this.state.body,
                          3
                        )
                      )}
                      onChange={this.changeUserData("username", 3, "string")}
                      required={this.isRequired("username")()}
                      validationMessage="Длина должна быть не меньше 3 символов"
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      text="Email"
                      value={this.state.body.email}
                      onChange={this.changeUserData("email", 3, "string")}
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      star={true}
                      text="Фамилия"
                      value={this.state.body.surname}
                      onChange={this.changeUserData("surname", 3, "string")}
                      onBlur={this.isTouched(
                        "surname",
                        this.validateUsersDataLength(
                          "surname",
                          this.state.body,
                          3
                        )
                      )}
                      required={this.isRequired("surname")()}
                      validationMessage="Длина фамилии не должно быть меньше 3 букв"
                    />
                  </div>
                </div>
                <div className="active-header">
                  <div className="input-height">
                    <Input
                      star={true}
                      text="Имя"
                      value={this.state.body.firstName}
                      onChange={this.changeUserData("firstName", 3, "string")}
                      onBlur={this.isTouched(
                        "firstName",
                        this.validateUsersDataLength(
                          "firstName",
                          this.state.body,
                          3
                        )
                      )}
                      required={this.isRequired("firstName")()}
                      validationMessage="Длина имени не должно быть меньше 3 букв"
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      text="Отчество"
                      value={this.state.body.patronymic}
                      onChange={this.changeUserData("patronymic", 1, "string")}
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      text="Название банка"
                      value={this.state.body.bankName}
                      onChange={this.changeUserData("bankName", 3, "string")}
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      type="number"
                      text="Номер карты"
                      value={this.state.body.bankCardNumber}
                      onChange={this.changeUserData(
                        "bankCardNumber",
                        1,
                        "number"
                      )}
                    />
                  </div>
                </div>
                <div className="active-header">
                  <div className="input-height">
                    <Input
                      text="Номер паспорта"
                      value={this.state.body.passport.code}
                      onChange={this.changePassportData("code", 3, "string")}
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      text="ИНН паспорта"
                      value={this.state.body.passport.itn}
                      onChange={this.changePassportData("itn", 3, "number")}
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      text="Орган выдавший паспорт"
                      value={this.state.body.passport.issuedBy}
                      onChange={this.changePassportData(
                        "issuedBy",
                        6,
                        "string"
                      )}
                    />
                  </div>
                  <div className="input-height">
                    <ReactDatePicker
                      variant="basic"
                      text="Дата выдачи паспорта"
                      selected={this.state.body.passport.issuedDate}
                      onChange={this.dateChange}
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </div>
                </div>
                <div className="active-header">
                  <div className="input-height-2">
                    <ReactSelect
                      text="Страна"
                      placeholder="Выберите страну"
                      onChange={this.changeCountryId}
                      options={getOptions(this.state.country)}
                    />
                  </div>
                  <div className="input-height-2">
                    <ReactSelect
                      value={this.state.regionId}
                      text="Регион"
                      placeholder="Выберите регион..."
                      onChange={this.changeRegionId}
                      options={getOptions(this.state.region)}
                    />
                  </div>
                  <div className="input-height-2">
                    <ReactSelect
                      value={this.state.cityId}
                      text="Город"
                      placeholder="Выберите город..."
                      onChange={this.changeCityId}
                      options={getOptions(this.state.cities)}
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      text="Номер дома"
                      value={this.state.body.address.house}
                      onChange={this.changeAddressData("house", 1)}
                    />
                  </div>
                  <div className="input-height">
                    <Input
                      text="Название улицы"
                      value={this.state.body.address.street}
                      onChange={this.changeAddressData("street", 3)}
                    />
                  </div>
                </div>
              </div>
              <div className="btn-text-center">
                <Button
                  variant={buttonType}
                  onClick={this.sendRequest}
                  disabled={this.state.disabled}
                >
                  Сохранить
                </Button>
                {this.state.incorrectText && (
                  <p className="add-wrong">
                    {this.state.errorText}
                  </p>
                )}
                {this.state.successText && (
                  <p className="add-correct">Успешно сохранено</p>
                )}
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={this.state.modal}
          onRequestClose={this.closeModal}
          className="modal"
          overlayClassName="overlay"
        >
          <span className="exit-span" onClick={this.closeModal}>
            X
          </span>
          <ChangePassword
            url={`/api/v1/admin/partners/${this.state.id}/change-password`}
          />
        </Modal>
      </>
    );
  }
}
export default EditPartner;
