import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../input/input.scss"

export default function ReactDatePicker(props) {
  const {variant = "basic"} = props
  return (
    <div className={`${props.className} ${variant}`}>
      <div>
      <label>{props.text}</label>
      </div>
      <div className="datepicker-width">
      <DatePicker
        selected={props.selected}
        onChange={props.onChange}
        showYearDropdown={props.showYearDropdown}
        dateFormatCalendar={props.dateFormatCalendar}
        yearDropdownItemNumber={props.yearDropdownItemNumber}
        scrollableYearDropdown={props.scrollableYearDropdown}
      />
      </div>
      <span className="validation-message">
        {props.required === true && <span>{props.validationMessage}</span>}
      </span>
    </div>
  );
}
