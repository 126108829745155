import React from "react";
import { connect } from "react-redux";
import Loader from "../../Components/loader/loader";
import ReactSelect from "../../Components/react-select/react-select";
import Button from "../../Components/Button/Button";
import { getPages } from "../../helper/pagination/pagination";
import { DEFAULT_PAGINATION } from "../partners/AllPartners";
import {
  getOptions,
  getRequest,
  sendRequest,
} from "../../helper/forCountries/forCountries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";

class PurchaseReport extends React.Component {
  state = {
    loader: false,
    mainLoader: true,
    body: {
      pageRequest: DEFAULT_PAGINATION,
      sorting: {
        sortBy: "AMOUNT",
        sortDirection: "ASC",
      },
      filter: {
        partnerId: "",
        week: "",
        createdAtFrom: "",
        createdAtTo: "",
        countryId: "",
        regionId: "",
        cityId: "",
      },
    },
    searchPartner: {
      pageRequest: {
        page: 1,
        limit: 5,
      },
      filter: {
        searchParameter: "",
      },
    },
    partnerList: [],
    purchaseContent: {
      content: [],
    },
    accountType: this.props.meReducer.data.accountType,
    country: [],
    region: [],
    city: [],
    currentWeek: null,
    weeks: [],
    countryId: null,
    regionId: null,
    cityId: null,
  };
  componentDidMount() {
    getRequest(`/api/v1/weeks`)
      .then((data) =>
        this.setState(
          { ...this.state, currentWeek: data.data.currentWeek },
          () => this.weeksOptions()
        )
      )
      .catch((e) => console.log(e));
    if (this.state.accountType === "PARTNER") {
      this.sendRequestToPurchasePartner();
    } else {
      this.searchPurchaseList();
    }
  }
  sendRequestToPurchasePartner = () => {
    sendRequest(`/api/v1/histories/purchase`, this.state.body)
      .then((data) =>
        this.setState(
          {
            ...this.state,
            purchaseContent: data.data,
          },
          () => this.getCountries()
        )
      )
      .catch((e) => console.error(e));
  };
  getCountries = () => {
    getRequest("/api/v1/common/countries")
      .then((data) =>
        this.setState({
          ...this.state,
          country: data.data.data,
          mainLoader: false,
          loader: false,
        })
      )
      .catch((e) => console.error("error: ", e));
  };
  searchPurchaseList = () => {
    let url = `/api/v1/histories/purchase`;
    if (this.state.accountType === "EMPLOYEE") {
      url = `/api/v1/histories/admin/purchase`;
    }
    this.setState({ ...this.state, loader: true });
    sendRequest(url, this.state.body)
      .then((data) =>
        this.setState({ ...this.state, purchaseContent: data.data }, () => {
          this.getCountries();
        })
      )
      .catch((e) => console.error(e));
  };
  weeksOptions = () => {
    let arr = [{ name: "Все недели", id: null }];
    for (let i = 1; i <= this.state.currentWeek; i += 1) {
      arr.push({ name: i, id: i });
    }
    this.setState({ ...this.state, weeks: arr });
  };
  getPurchaseList = () => {
    return this.state.purchaseContent.content.map((item, index) => {
      return (
        <tr key={index}>
          {this.state.accountType === "EMPLOYEE" && (
            <td data-label="Ф.И.О">
              <p>{item.partner.code}</p>
              <p>{item.partner.surname}</p>
              <p>{item.partner.firstName}</p>
              <p>{item.partner.patronymic}</p>
            </td>
          )}
          <td data-label="Название">{item.product.name}</td>
          <td data-label="Цена">
            {item.product.price} <FontAwesomeIcon icon={faCoins} />
          </td>
          <td data-label="Количество">{item.quantity} шт</td>
          <td data-label="Потрчено">
            {item.amount} <FontAwesomeIcon icon={faCoins} />
          </td>
          <td data-label="Неделя">{item.week}</td>
        </tr>
      );
    });
  };
  searchPartners = () => {
    sendRequest(`/api/v1/admin/partners/search`, this.state.searchPartner)
      .then((data) =>
        this.setState({ ...this.state, partnerList: data.data.content })
      )
      .catch((e) => console.log(e));
  };
  handleInputChange = (e, { action }) => {
    if (action === "input-change") {
      this.setState(
        {
          ...this.state,
          body: {
            ...this.state.body,
            pageRequest: DEFAULT_PAGINATION,
            filter: { ...this.state.body.filter, partnerId: null },
          },
          searchPartner: {
            ...this.state.searchPartner,
            filter: {
              searchParameter: e,
            },
          },
        },
        async () => this.searchPartners()
      );
    }
  };
  options = (data) => {
    return this.state[data].map((item) => {
      return {
        label: `${item.surname} ${item.firstName} ${item.code}`,
        value: item.id,
      };
    });
  };
  changeAddressId = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        [data]: { label: e ? e.label : null, value: e ? e.value : null },
        body: {
          ...this.state.body,
          pageRequest: DEFAULT_PAGINATION,
          filter: { ...this.state.body.filter, [data]: e ? e.value : null },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (data === "countryId") {
          this.setState({
            ...this.state,
            body: {
              ...this.state.body,
              filter: {
                ...this.state.body.filter,
                regionId: null,
                cityId: null,
              },
            },
            region: [],
            city: [],
            regionId: null,
            cityId: null,
          });
        } else if (data === "regionId") {
          this.setState({
            ...this.state,
            body: {
              ...this.state.body,
              filter: {
                ...this.state.body.filter,
                cityId: null,
              },
            },
            city: [],
            cityId: null,
          });
        }
        if (foo !== null) {
          foo();
        }
      }
    );
  };
  citiesOnRegion = () => {
    if (this.state.body.filter.regionId !== null) {
      getRequest(
        `/api/v1/common/cities/find-by-region/${this.state.body.filter.regionId}`,
        this.state.body
      ).then((data) =>
        this.setState({
          ...this.state,
          city: data.data.data,
        })
      );
    }
  };
  regionsOnCountry = () => {
    if (this.state.body.filter.countryId !== null) {
      getRequest(
        `/api/v1/common/regions/find-by-country/${this.state.body.filter.countryId}`,
        this.state.body
      ).then((data) =>
        this.setState({
          ...this.state,
          region: data.data.data,
        })
      );
    }
  };
  selectPartner = (e) => {
    this.setState(
      {
        ...this.state,
        loader: true,
        body: {
          ...this.state.body,
          pageRequest: DEFAULT_PAGINATION,
          filter: { ...this.state.body.filter, partnerId: e ? e.value : null },
        },
        searchPartner: {
          ...this.state.searchPartner,
          filter: {
            searchParameter: e ? e.label : "",
          },
        },
      },
      () => this.searchPurchaseList()
    );
  };
  changePage = (page) => () => {
    this.setState(
      {
        ...this.state,
        loader: true,
        body: {
          ...this.state.body,
          pageRequest: { page: page, limit: DEFAULT_PAGINATION.limit },
        },
      },
      () => this.searchPurchaseList()
    );
  };
  sortBy = (value) => () => {
    if (value !== this.state.body.sorting.sortBy) {
      this.setState(
        {
          ...this.state,
          body: {
            ...this.state.body,
            sorting: { ...this.state.body.sorting, sortBy: value },
          },
        },
        () => this.searchPurchaseList()
      );
    }
  };
  render() {
    return (
      <>
        {this.state.mainLoader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            {this.props.meReducer.data.accountType === "PARTNER" && (
              <div className="inactive-partnership">
                {this.props.meReducer.data.partnershipStatus !== "ACTIVE" && (
                  <span>
                    <b>У вас не активирован статус партнерства!</b>
                  </span>
                )}
              </div>
            )}
            <div className="categories_bar">
              <div className="filter-header">
                {this.state.accountType === "EMPLOYEE" && (
                  <div className="searchBlock">
                    <ReactSelect
                      value={this.state.body.filter.partnerId}
                      inputValue={
                        this.state.searchPartner.filter.searchParameter
                      }
                      filterOption={(option) => option}
                      placeholder="Найдите партнера..."
                      text="Выберите партнера"
                      onInputChange={this.handleInputChange}
                      onChange={this.selectPartner}
                      options={this.options("partnerList")}
                    />
                  </div>
                )}
                <div className="filter-block">
                  {this.state.accountType === "EMPLOYEE" && (
                    <div className="filter-select">
                      <div className="filter-select-height">
                        {" "}
                        <ReactSelect
                          filterOption={(option) => option}
                          text="Страна"
                          placeholder="Выберите страну"
                          onChange={this.changeAddressId(
                            "countryId",
                            this.regionsOnCountry
                          )}
                          options={getOptions(this.state.country)}
                        />
                      </div>
                      <div className="filter-select-height">
                        <ReactSelect
                          value={this.state.regionId}
                          text="Регион"
                          placeholder="Выберите регион"
                          onChange={this.changeAddressId(
                            "regionId",
                            this.citiesOnRegion
                          )}
                          options={getOptions(this.state.region)}
                        />
                      </div>
                      <div className="filter-select-height">
                        <ReactSelect
                          value={this.state.cityId}
                          text="Город"
                          placeholder="Выберите город"
                          onChange={this.changeAddressId("cityId", null)}
                          options={getOptions(this.state.city)}
                        />
                      </div>
                    </div>
                  )}
                  <div className="filter-input">
                    <ReactSelect
                      text="Неделя"
                      placeholder="Выберите неделю"
                      onChange={this.changeAddressId("week", null)}
                      options={getOptions(this.state.weeks)}
                    />
                  </div>
                  <div className="filter-button">
                    <Button variant="blue" onClick={this.searchPurchaseList}>
                      Поиск
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loader ? (
              <div className="loader-container">
                <Loader className="center" width="25px" height="25px" />
              </div>
            ) : (
              <>
                {this.state.purchaseContent.content[0] && (
                  <>
                    <div className="table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            {this.state.accountType === "EMPLOYEE" && (
                              <th>Ф.И.О</th>
                            )}
                            <th scope="col">Название товара</th>
                            <th scope="col">Стоимость товара</th>
                            <th scope="col">Количество</th>
                            <th scope="col">
                              Потрачено денег{" "}
                              <span
                                className="cursor"
                                onClick={this.sortBy("AMOUNT")}
                              >
                                <FontAwesomeIcon icon={faSortAmountUp} />
                              </span>
                            </th>
                            <th scope="col">
                              Неделя{" "}
                              <span
                                className="cursor"
                                onClick={this.sortBy("WEEK")}
                              >
                                <FontAwesomeIcon icon={faSortAmountUp} />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.getPurchaseList()}</tbody>
                      </table>
                    </div>
                    <div className="span-page">
                      {getPages(
                        this.state.purchaseContent,
                        this.state.body.pageRequest.page,
                        this.changePage
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};
export default connect(mapStateToProps, null)(PurchaseReport);
