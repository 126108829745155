import React from "react";
import { getRequest } from "../../../helper/forCountries/forCountries";
import Button from "../../../Components/Button/Button";
import { Link } from "react-router-dom";
import AddRegion from "./addRegion";
import EditRegion from "./editRegion";
import Loader from "../../../Components/loader/loader";
import { faCity, faMapPin, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortFn } from "../../../helper/sort";
import { connect } from "react-redux";
import Modal from "react-modal";

class AllRegions extends React.Component {
  state = {
    regions: [],
    country: "",
    addModal: false,
    changeModal: false,
    id: "",
    loader: true,
  };
  componentDidMount() {
    getRequest(`/api/v1/common/countries/${this.props.match.params.id}`)
      .then((data) => this.setState({ ...this.state, country: data.data.name }))
      .catch((e) => console.error(e));
    this.getRequest();
  }
  getRequest = () => {
    getRequest(
      `/api/v1/common/regions/find-by-country/${this.props.match.params.id}`
    )
      .then((data) =>
        this.setState({
          ...this.state,
          regions: sortFn(data.data.data),
          loader: false,
        })
      )
      .catch((e) => console.error(e));
  };
  changeId = (id) => () => {
    this.setState({ ...this.state, id: id }, () => this.editModal());
  };
  listAllRegions = () =>
    this.state.regions.map((item) => {
      return (
        <tr key={item.id}>
          <td data-label="ID страны">
            <b>{item.country.id}.</b>
          </td>
          <td data-label="Название">{item.country.name}</td>
          <td data-label="ID">
            <b>{item.id}.</b>
          </td>
          <td data-label="Название">
            <b>{item.name}</b>
          </td>
          <td data-label="Действия">
            <Link to={`/users/all-cities/${item.id}`}>
              <Button variant="blue">
                <FontAwesomeIcon icon={faCity} /> перейти к городам
              </Button>
            </Link>
            {this.props.meReducer.data.role === "ADMIN" && (
              <Button variant="secondary" onClick={this.changeId(item.id)}>
                <FontAwesomeIcon icon={faPen} /> изменить
              </Button>
            )}
          </td>
        </tr>
      );
    });
  closeEditModal = (data) => () => {
    this.setState({ ...this.state, [data]: false }, () => this.getRequest());
  };
  editModal = () => {
    this.setState({ ...this.state, changeModal: true });
  };
  openModal = () => {
    this.setState({ ...this.state, addModal: true });
  };
  render() {
    const id = this.props.match.params.id;
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            <div className="categories_bar">
              <div className="active-header">
                <div className="active-header-button">
                  <h2>{this.state.country}</h2>
                </div>
                <div className="active-header-button">
                  <Button variant="blue" onClick={this.openModal}>
                    <FontAwesomeIcon icon={faMapPin} /> Добавить регион
                  </Button>
                </div>
              </div>
            </div>
            {this.state.regions[0] ? (
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">ID страны</th>
                      <th scope="col">Название страны</th>
                      <th scope="col">ID региона</th>
                      <th scope="col">Название региона</th>
                      <th scope="col">Действия</th>
                    </tr>
                  </thead>
                  <tbody>{this.listAllRegions()}</tbody>
                </table>
              </div>
            ) : (
              <p>
                <b>Отсутствуют данные о регионах, возможно вы не добавили их</b>
              </p>
            )}
            <Modal
              className="modal"
              overlayClassName="overlay"
              isOpen={this.state.addModal}
              onRequestClose={this.closeEditModal("addModal")}
            >
              <span
                className="exit-span"
                onClick={this.closeEditModal("addModal")}
              >
                X
              </span>
              <AddRegion id={id} />
            </Modal>
            <Modal
              className="modal"
              overlayClassName="overlay"
              isOpen={this.state.changeModal}
              onRequestClose={this.closeEditModal("changeModal")}
            >
              <span
                className="exit-span"
                onClick={this.closeEditModal("changeModal")}
              >
                X
              </span>
              <EditRegion id={this.state.id} />
            </Modal>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};

export default connect(mapStateToProps, null)(AllRegions);
