import React from "react";
import { validationForNumeric } from "../../helper/validateHelper/validateHelper";
import Input from "../../Components/input/Input";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/loader/loader";
import {
  getRequest,
  sendRequest,
} from "../../helper/forCountries/forCountries";

export default class CashOutBalance extends React.Component {
  state = {
    loader: true,
    body: {
      amount: 0,
    },
    balance: null,
    disabled: true,
    amount: false,
    incorrectText: false,
    successText: false,
    errorText: "",
  };
  componentDidMount() {
    getRequest(`/api/v1/balances/${this.props.id}`)
      .then((data) =>
        this.setState({ ...this.state, loader: false, balance: data.data })
      )
      .catch((e) => console.log(e));
  }
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: Number(e.target.value) },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  validateForNumeric = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: validationForNumeric(this.state.body[data]),
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isDisabledButton = () => {
    const state = this.state;
    return state.amount === false;
  };
  sendRequest = () => {
    sendRequest(`/api/v1/balances/${this.props.id}`, this.state.body)
      .then(() => this.setState({ ...this.state, successText: true }))
      .catch((e) =>
        this.setState({
          ...this.state,
          successText: false,
          incorrectText: true,
          errorText: e.response.data.message
        })
      );
  };
  render() {
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container-minimize">
            <Loader className="center" width="15px" height="15px" />
          </div>
        ) : (
          <>
            <div className="modal-page">
              <div className="categories_bar">
                <span>Баланс {this.state.balance.balance}</span>
                <Input
                  star={true}
                  text="Сумма"
                  value={this.state.body.amount}
                  onChange={this.changeUserData(
                    "amount",
                    this.validateForNumeric("amount")
                  )}
                  onBlur={this.validateForNumeric("amount")}
                  required={this.state.amount}
                  validationMessage="Поле не должно быть пустым и должен содержать только цифру(ы)"
                />
                <div className="active-header">
                  <Button
                    variant="secondary"
                    disabled={this.state.disabled}
                    onClick={this.sendRequest}
                  >
                    Выдать
                  </Button>
                  {this.state.incorrectText && (
                    <span className="error-message">
                      {this.state.errorText}
                    </span>
                  )}
                  {this.state.successText && (
                    <span className="success-message">
                      Выплата прошла успешно
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
