
export default function meReducer (state = {}, action) {
  switch (action.type) {
    case "ME_DATA":
      return{
        data: action.data
      }
    case "ME_ERROR":
      return{
        error: action.data
      }
    default:
      return state
  }
}