import React from "react";
import Button from "../../Components/Button/Button";
import { Link } from "react-router-dom";
import Input from "../../Components/input/Input";
import "./AllPartners.scss";
import { sendRequest } from "../../helper/forCountries/forCountries";
import Loader from "../../Components/loader/loader";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faDollarSign,
  faSortAmountUp,
  faUserPlus,
  faWallet,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { getPages } from "../../helper/pagination/pagination";
import Status from "../status/status";
import { translateType } from "../../helper/sort";
import CashOutBalance from "../Balance/cashOutBalance";
import PartnerInfo from "./partnerInfo/partnerInfo";
import PurchaseAll from "../purchase/purchaseAll";
export const DEFAULT_PAGINATION = {
  page: 1,
  limit: 10,
};
class AllPartners extends React.Component {
  state = {
    statusModal: false,
    purchaseModal: false,
    cashOutModal: false,
    infoModal: false,
    partnerContent: {},
    id: null,
    status: null,
    pagination: {
      pageRequest: { ...DEFAULT_PAGINATION },
      sorting: {
        sortBy: "ID",
        sortDirection: "ASC",
      },
      filter: {
        searchParameter: "",
      },
    },
    users: {},
    loader: true,
  };
  componentDidMount() {
    this.sendRequest();
  }
  sendRequest = () => {
    sendRequest("/api/v1/admin/partners/search", this.state.pagination)
      .then((data) =>
        this.setState({
          users: data.data,
          loader: false,
        })
      )
      .catch((e) => console.error("Error: ", e));
  };
  openInfoModal = (item) => () => {
    this.setState({ ...this.state, partnerContent: item }, () =>
      this.setState({ ...this.state, infoModal: true })
    );
  };
  openCashOutModal = (id) => () => {
    this.setState({ ...this.state, cashOutModal: true, id: id });
  };
  openPurchaseModal = (id) => () => {
    this.setState({ ...this.state, purchaseModal: true, id: id });
  };
  openStatusModal = (id, status) => () => {
    this.setState({ ...this.state, statusModal: true, id: id, status: status });
  };
  getEmployee = () => {
    if (this.state.users.content) {
      return this.state.users.content.map((item) => {
        return (
          <tr key={item.id}>
            <td data-label="ID">{item.id}</td>
            <td data-label="Логин">{item.username}</td>
            <td data-label="Ф.И.О">
              <b>
                <span className="cursor" onClick={this.openInfoModal(item)}>
                  {item.surname} {item.firstName} {item.patronymic ? (
                  <span>{item.patronymic}</span>
                ) : (
                  <span>{""}</span>
                )}
                </span>
              </b>
            </td>
            <td data-label="Cтатус партнерства">
              {translateType(item.partnershipStatus)}
            </td>
            <td data-label="Наставник">
              {item.mentor !== null ? (
                <span>
                  {item.mentor.code}{" "}
                  {item.mentor.surname} {item.mentor.firstName}{" "}
                  {item.mentor.patronymic ? (
                    <span>{item.mentor.patronymic}</span>
                  ) : (
                    <span>{""}</span>
                  )}
                </span>
              ) : (
                <span>-</span>
              )}
            </td>
            <td data-label="Рекомендатель">
              {item.proposer !== null ? (
                <span>
                  {item.proposer.code}{" "}
                  {item.proposer.surname} {item.proposer.firstName}{" "}
                  {item.proposer.patronymic ? (
                    <span>{item.proposer.patronymic}</span>
                  ) : (
                    <span>{""}</span>
                  )}
                </span>
              ) : (
                <span>-</span>
              )}
            </td>
            {this.props.meReducer.data.role === "ADMIN" &&<td data-label="Изменить">
              <Link className="btn link" to={`/users/edit-partner/${item.id}`}>
                <span className="span-button">
                  <FontAwesomeIcon icon={faWrench} />
                  Изменить
                </span>
              </Link>
            </td>}
            <td data-label="Покупка">
              {item.username !== "root" ? (
                <>
                    <Button
                      onClick={this.openPurchaseModal(item.id)}
                      variant="blue"
                    >
                      <span className="span-button">
                        <FontAwesomeIcon icon={faWallet} />
                        Купить
                      </span>
                    </Button>
                  {this.props.meReducer.data.accountType === "EMPLOYEE" &&
                    this.props.meReducer.data.role !== "OPERATOR" && (
                      <Button
                        onClick={this.openCashOutModal(item.id)}
                        variant="blue"
                      >
                        <span className="span-button">
                          <FontAwesomeIcon icon={faWallet} />
                          Выдать
                        </span>
                      </Button>
                    )}
                </>
              ) : <span>-</span>}
            </td>
            <td data-label="Статус аккаунта">
              <div>
                {translateType(item.status)}{" "}
                <Button
                  variant="secondary"
                  onClick={this.openStatusModal(item.id, item.status)}
                >
                  <FontAwesomeIcon icon={faWrench} /> Изменить
                </Button>
              </div>
            </td>
          </tr>
        );
      });
    }
  };
  changePage = (page) => () => {
    this.setState(
      {
        ...this.state,
        pagination: {
          ...this.state.pagination,
          pageRequest: { page: page, limit: DEFAULT_PAGINATION.limit },
        },
      },
      () =>
        sendRequest("/api/v1/admin/partners/search", this.state.pagination)
          .then((data) =>
            this.setState({
              users: data.data,
            })
          )
          .catch((e) => console.error("Error: ", e))
    );
  };
  changeStateData = (e) => {
    this.setState(
      {
        ...this.state,
        pagination: {
          ...this.state.pagination,
          pageRequest: { ...DEFAULT_PAGINATION },
          filter: {
            searchParameter: e.target.value,
          },
        },
      },
      () =>
        sendRequest("/api/v1/admin/partners/search", this.state.pagination)
          .then((data) =>
            this.setState({
              users: data.data,
            })
          )
          .catch((e) => console.error("Error: ", e))
    );
  };
  closeModal = (data) => () => {
    this.setState({ ...this.state, [data]: false }, () => this.sendRequest());
  };
  sortBy = (value) => () => {
    if (value !== this.state.pagination.sorting.sortBy) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            sorting: { ...this.state.pagination.sorting, sortBy: value },
          },
        },
        () => this.sendRequest()
      );
    }
  };
  render() {
    return (
      <React.Fragment>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            <div className="categories_bar">
              <div className="active-header">
                <Input
                  variant="search"
                  placeholder="Поиск..."
                  text="Найти"
                  onChange={this.changeStateData}
                />
                <div className="active-header-button">
                  <Link to="/users/add-partner">
                    <Button variant="blue">
                      <b>
                        <FontAwesomeIcon icon={faUserPlus} /> Добавить
                      </b>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            {!!this.state.users.content[0] ? (
              <>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">
                          ID{" "}
                          <span className="cursor" onClick={this.sortBy("ID")}>
                            <FontAwesomeIcon icon={faSortAmountUp} />
                          </span>
                        </th>
                        <th scope="col">
                          Логин{" "}
                          <span
                            className="cursor"
                            onClick={this.sortBy("USERNAME")}
                          >
                            <FontAwesomeIcon icon={faSortAmountUp} />
                          </span>
                        </th>
                        <th scope="col">
                          Ф.И.О{" "}
                          <span
                            className="cursor"
                            onClick={this.sortBy("SURNAME")}
                          >
                            <FontAwesomeIcon icon={faSortAmountUp} />
                          </span>
                        </th>
                        <th scope="col">Статус партнерства</th>
                        <th scope="col">Наставник</th>
                        <th scope="col">Реко-датель</th>
                        {this.props.meReducer.data.role === "ADMIN" &&<th scope="col">
                          <span>
                            <FontAwesomeIcon icon={faCogs} />
                          </span>
                        </th>}
                        <th scope="col">
                          <span>
                            Баланс <FontAwesomeIcon icon={faDollarSign} />
                          </span>
                        </th>
                        <th scope="col">Статус аккаунта</th>
                      </tr>
                    </thead>
                    <tbody>{this.getEmployee()}</tbody>
                  </table>
                </div>
                <div className="span-page">
                  {getPages(
                    this.state.users,
                    this.state.pagination.pageRequest.page,
                    this.changePage
                  )}
                </div>
              </>
            ) : (
              <p>Отсутствует список партнеров, добавьте их</p>
            )}
          </>
        )}
        <Modal
          isOpen={this.state.purchaseModal}
          onRequestClose={this.closeModal("purchaseModal")}
          className="modal"
          overlayClassName="overlay"
        >
          <span
            className="exit-span"
            onClick={this.closeModal("purchaseModal")}
          >
            X
          </span>
          <PurchaseAll id={this.state.id} />
        </Modal>
        <Modal
          className="modal"
          overlayClassName="overlay"
          isOpen={this.state.statusModal}
          onRequestClose={this.closeModal("statusModal")}
        >
          <span className="exit-span" onClick={this.closeModal("statusModal")}>
            X
          </span>
          <Status data={{ status: this.state.status, id: this.state.id }} />
        </Modal>
        <Modal
          className="modal"
          overlayClassName="overlay"
          isOpen={this.state.cashOutModal}
          onRequestClose={this.closeModal("cashOutModal")}
        >
          <span className="exit-span" onClick={this.closeModal("cashOutModal")}>
            X
          </span>
          <CashOutBalance id={this.state.id} />
        </Modal>
        <Modal
          className="modal"
          overlayClassName="overlay"
          isOpen={this.state.infoModal}
          onRequestClose={this.closeModal("infoModal")}
        >
          <span className="exit-span" onClick={this.closeModal("infoModal")}>
            X
          </span>
          <PartnerInfo info={this.state.partnerContent} />
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};
export default connect(mapStateToProps, null)(AllPartners);
