import React from "react";
import Loader from "../../Components/loader/loader";
import ReactSelect from "../../Components/react-select/react-select";
import { ArcherContainer, ArcherElement } from "react-archer";
import { arrowFn } from "../../helper/tree-arrow-helper/helper";
import { connect } from "react-redux";
import {
  getOptions,
  getRequest,
  sendRequest,
} from "../../helper/forCountries/forCountries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../Components/Button/Button";
import { faHandPointUp, faSearch } from "@fortawesome/free-solid-svg-icons";
// import Tooltip from "../../Components/tooltip/tooltip";

class Structure extends React.Component {
  state = {
    structureBody: {
      partnerId: null,
      week: null,
    },
    searchProposer: {
      pageRequest: {
        page: 1,
        limit: 5,
      },
      filter: {
        searchParameter: "",
      },
    },
    miniLoader: false,
    rootId: null,
    currentWeek: null,
    weeks: [],
    partnerId: null,
    parentId: null,
    visible: false,
    modalContent: [],
    partners: [],
    loader: true,
    errorText: false,
    content: {
      leftPartner: null,
      rightPartner: null,
      firstName: "",
      leftTotal: 0,
      partnerId: 0,
      partnerStage: {
        left: 0,
        right: 0,
        stageDto: {
          bonusOnComplete: 0,
          orderIndex: 0,
          type: "INITIAL",
        },
      },
      leftCurrent: 0,
      rightCurrent: 0,
      rightTotal: 0,
      surname: "",
      username: "",
    },
    body: {
      pageRequest: {
        page: 1,
        limit: 1,
      },
      filter: {
        username: "",
      },
    },
  };
  componentDidMount() {
    getRequest(`/api/v1/weeks`)
      .then((data) =>
        this.setState(
          { ...this.state, currentWeek: data.data.currentWeek },
          () => this.weeksOptions()
        )
      )
      .catch((e) => console.log(e));
    if (this.props.meReducer.data.accountType === "EMPLOYEE") {
      this.sendRequest();
    } else if (this.props.meReducer.data.accountType === "PARTNER") {
      sendRequest(`/api/v1/partners/structure`, {
        partnerId: this.props.meReducer.data.id,
        week: null,
      })
        .then((data) => {
          this.setState({
            ...this.state,
            content: data.data,
            loader: false,
            partnerId: data.data.partnerId,
          });
        })
        .catch(() =>
          this.setState({ ...this.state, errorText: true, loader: false })
        );
    }
  }
  sendRequest = () => {
    sendRequest("/api/v1/admin/partners/search", this.state.body)
      .then((data) => {
        this.getRequest(data.data.content[0].id)();
        this.setState({
          ...this.state,
          rootId: data.data.content[0].id,
          loader: false,
        });
      })
      .catch(() => this.setState({ ...this.state, errorText: true }));
  };
  getRequest = (data) => () => {
    this.setState({ ...this.state, miniLoader: true }, () =>
      sendRequest(`/api/v1/${this.roleFn()}/structure`, {
        partnerId: data,
        week: this.state.structureBody.week,
      })
        .then((data) => {
          this.setState({
            ...this.state,
            content: data.data,
            parentId: data.data.parentId,
            miniLoader: false,
          });
        })
        .catch(() =>
          this.setState({ ...this.state, errorText: true, loader: false })
        )
    );
  };
  roleFn = () => {
    if (this.props.meReducer.data.accountType === "EMPLOYEE") {
      return "admin/partners";
    } else {
      return "partners";
    }
  };
  handleInputChange = (e, { action }) => {
    if (action === "input-change") {
      this.setState(
        {
          ...this.state,
          structureBody: { ...this.state.structureBody, partnerId: null },
          searchProposer: {
            pageRequest: {
              page: 1,
              limit: 5,
            },
            filter: {
              searchParameter: e,
            },
          },
        },
        () => {
          sendRequest(
            "/api/v1/admin/partners/search",
            this.state.searchProposer
          )
            .then((data) =>
              this.setState(
                { ...this.state, partners: data.data.content },
                () => {
                  this.options("partners");
                }
              )
            )
            .catch((e) => console.log(e));
        }
      );
    }
  };
  weeksOptions = () => {
    let arr = [{ name: "Все недели", id: null }];
    for (let i = 1; i <= this.state.currentWeek; i += 1) {
      arr.push({ name: i, id: i });
    }
    this.setState({ ...this.state, weeks: arr });
  };
  changeData = (e) => {
    this.setState({
      ...this.state,
      searchProposer: {
        ...this.state.searchProposer,
        filter: { searchParameter: e ? e.label : "" },
      },
      structureBody: {
        ...this.state.structureBody,
        partnerId: e ? e.value : null,
      },
    });
  };
  changeDataWeek = (e) => {
    this.setState({
      ...this.state,
      structureBody: { ...this.state.structureBody, week: e ? e.value : null },
    });
  };
  options = () => {
    return this.state.partners.map((item) => {
      return {
        label: `${item.surname} ${item.firstName} ${item.code}`,
        value: item.id,
      };
    });
  };
  drawList = (content, fn) => {
    let red = "";
    if (content.partnershipStatus === "INACTIVE" ||
      content.partnershipStatus === "DEACTIVATED") {
      red = "red-text";
    }
    return (
      <>
        <div className={`structure-text ${red}`} onClick={fn}>
          <p><b>{content.code}</b></p>
          <p>
            <i>
              <b>{content.firstName}</b>
            </i>
          </p>
          <p>
            <i>
              <b>{content.surname}</b>
            </i>
          </p>
          <p>
            <i>
              <b>{content.patronymic}</b>
            </i>
          </p>
          <p><span>Л всего: <b>{content.leftTotal}</b></span></p>
          <p><span>Л текущий: <b>{content.leftCurrent}</b></span></p>
          <p><span>П всего: <b>{content.rightTotal}</b></span></p>
          <p><span>П текущий: <b>{content.rightCurrent}</b></span></p>
          <p><span>Цикл: <b>{content.currentCycle}</b></span></p>
          <p><span>Этап текущий: <b>{content.currentStage}</b></span></p>
        </div>
      </>
    );
  };
  drawTable = (content, fn) => {
    let redClass;
    if (
      content.partnershipStatus === "INACTIVE" ||
      content.partnershipStatus === "DEACTIVATED"
    ) {
      redClass = "inactive";
    }
    return (
      <table className={`structure-table ${redClass}`} onClick={fn}>
        <thead>
          <tr>
            <th>
              <p>
                <i>{content.code}</i>
              </p>
              <p>
                <i>{content.firstName}</i>
              </p>
              <p>
                <i>{content.surname}</i>
              </p>
              <p>
                <i>{content.patronymic}</i>
              </p>
            </th>
            <th>Всего</th>
            <th>Текущий</th>
          </tr>
        </thead>
        <tbody>
          {" "}
          <tr>
            <td>
              <b>Слева</b>
            </td>
            <td>{content.leftTotal}</td>
            <td>{content.leftCurrent}</td>
          </tr>
          <tr>
            <td>
              <b>Справа</b>
            </td>
            <td>{content.rightTotal}</td>
            <td>{content.rightCurrent}</td>
          </tr>
          <tr>
            <td>
              <b>Цикл</b>
            </td>
            <td> </td>
            <td>{content.currentCycle}</td>
          </tr>
          <tr>
            <td>
              <b>Этап</b>
            </td>
            <td> </td>
            <td>{content.currentStage}</td>
          </tr>
        </tbody>
      </table>
    );
  };
  render() {
    const content = this.state.content;
    let none = "";
    if (
      this.state.partnerId === content.partnerId ||
      content.partnerId === this.state.rootId
    ) {
      none = "none";
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            {this.props.meReducer.data.accountType === "PARTNER" && (
              <div className="inactive-partnership">
                {this.props.meReducer.data.partnershipStatus !== "ACTIVE" && (
                  <span>
                    <b>У вас не активирован статус партнерства!</b>
                  </span>
                )}
              </div>
            )}
            {this.props.meReducer.data.accountType === "EMPLOYEE" && (
              <div className="categories_bar">
                <div className="structure-search-block">
                  <div className="search-block">
                    <ReactSelect
                      value={this.state.structureBody.partnerId}
                      inputValue={
                        this.state.searchProposer.filter.searchParameter
                      }
                      filterOption={(option) => option}
                      placeholder="Поиск..."
                      onInputChange={this.handleInputChange}
                      onChange={this.changeData}
                      options={this.options()}
                    />
                  </div>
                  <div className="week-block">
                    <ReactSelect
                      defaultValue={{
                        label: "Все недели",
                        value: this.state.structureBody.week,
                      }}
                      placeholder="Неделя..."
                      options={getOptions(this.state.weeks)}
                      onChange={this.changeDataWeek}
                    />
                  </div>
                  <div className="button-block">
                    <Button
                      variant="blue"
                      onClick={this.getRequest(
                        this.state.structureBody.partnerId
                      )}
                    >
                      <FontAwesomeIcon icon={faSearch} /> Поиск
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className={`structure-button ${none}`}>
              <Button
                onClick={this.getRequest(content.parentId)}
                variant="blue"
              >
                <FontAwesomeIcon icon={faHandPointUp} /> Вверх
              </Button>
            </div>
            <div className="structure-fon">
              {this.state.miniLoader ? (
                <div className="loader-container">
                  <Loader className="center" width="25px" height="25px" />
                </div>
              ) : (
                <div className="tree">
                  <ArcherContainer strokeColor="#333338">
                    <ArcherElement
                      id="root"
                      relations={arrowFn(content, "second")}
                    >
                      <div className="first">
                        <div className="index">
                          {/*<Tooltip content={content} />*/}
                          {this.drawList(content)}
                          {this.drawTable(content)}
                        </div>
                      </div>
                    </ArcherElement>
                    <div className="second">
                      <div className="index">
                        {content.leftPartner === null ? (
                          <span />
                        ) : (
                          <ArcherElement
                            id="left-second"
                            relations={arrowFn(
                              content.leftPartner,
                              "thirdFirst"
                            )}
                          >
                            <div>
                              {this.drawTable(
                                content.leftPartner,
                                this.getRequest(content.leftPartner.partnerId)
                              )}
                              <div>
                                {/*<Tooltip content={content.leftPartner} />*/}
                                {this.drawList(
                                  content.leftPartner,
                                  this.getRequest(content.leftPartner.partnerId)
                                )}
                              </div>
                            </div>
                          </ArcherElement>
                        )}
                      </div>
                      <div className="index">
                        {content.rightPartner === null ? (
                          <span />
                        ) : (
                          <ArcherElement
                            id="right-second"
                            relations={arrowFn(
                              content.rightPartner,
                              "thirdSecond"
                            )}
                          >
                            <div>
                              {this.drawTable(
                                content.rightPartner,
                                this.getRequest(content.rightPartner.partnerId)
                              )}
                              <div>
                                {/*<Tooltip content={content.rightPartner} />*/}
                                {this.drawList(
                                  content.rightPartner,
                                  this.getRequest(
                                    content.rightPartner.partnerId
                                  )
                                )}
                              </div>
                            </div>
                          </ArcherElement>
                        )}
                      </div>
                    </div>
                    <div className="third">
                      {content.leftPartner !== null && (
                        <>
                          {content.leftPartner.leftPartner === null ? (
                            <span />
                          ) : (
                            <ArcherElement id="left-thirdFirst">
                              <div className="index">
                                {this.drawTable(
                                  content.leftPartner.leftPartner,
                                  this.getRequest(
                                    content.leftPartner.leftPartner.partnerId
                                  )
                                )}
                                <div>
                                  {/*<Tooltip*/}
                                  {/*  content={content.leftPartner.leftPartner}*/}
                                  {/*/>*/}
                                  {this.drawList(
                                    content.leftPartner.leftPartner,
                                    this.getRequest(
                                      content.leftPartner.leftPartner.partnerId
                                    )
                                  )}
                                </div>
                              </div>
                            </ArcherElement>
                          )}
                          {content.leftPartner.rightPartner === null ? (
                            <span />
                          ) : (
                            <ArcherElement id="right-thirdFirst">
                              <div className="index">
                                {this.drawTable(
                                  content.leftPartner.rightPartner,
                                  this.getRequest(
                                    content.leftPartner.rightPartner.partnerId
                                  )
                                )}
                                <div>
                                  {/*<Tooltip*/}
                                  {/*  content={content.leftPartner.rightPartner}*/}
                                  {/*/>*/}
                                  {this.drawList(
                                    content.leftPartner.rightPartner,
                                    this.getRequest(
                                      content.leftPartner.rightPartner.partnerId
                                    )
                                  )}
                                </div>
                              </div>
                            </ArcherElement>
                          )}
                        </>
                      )}
                      {content.rightPartner !== null && (
                        <>
                          {content.rightPartner.leftPartner === null ? (
                            <span />
                          ) : (
                            <ArcherElement id="left-thirdSecond">
                              <div className="index">
                                {this.drawTable(
                                  content.rightPartner.leftPartner,
                                  this.getRequest(
                                    content.rightPartner.leftPartner.partnerId
                                  )
                                )}
                                <div>
                                  {" "}
                                  {/*<Tooltip*/}
                                  {/*  content={content.rightPartner.leftPartner}*/}
                                  {/*/>*/}
                                  {this.drawList(
                                    content.rightPartner.leftPartner,
                                    this.getRequest(
                                      content.rightPartner.leftPartner.partnerId
                                    )
                                  )}
                                </div>
                              </div>
                            </ArcherElement>
                          )}
                          {content.rightPartner &&
                          content.rightPartner.rightPartner === null ? (
                            <span />
                          ) : (
                            <ArcherElement id="right-thirdSecond">
                              <div className="index">
                                {this.drawTable(
                                  content.rightPartner.rightPartner,
                                  this.getRequest(
                                    content.rightPartner.rightPartner.partnerId
                                  )
                                )}
                                <div>
                                  {/*<Tooltip*/}
                                  {/*  content={content.rightPartner.rightPartner}*/}
                                  {/*/>*/}
                                  {this.drawList(
                                    content.rightPartner.rightPartner,
                                    this.getRequest(
                                      content.rightPartner.rightPartner
                                        .partnerId
                                    )
                                  )}
                                </div>
                              </div>
                            </ArcherElement>
                          )}
                        </>
                      )}
                    </div>
                  </ArcherContainer>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};
export default connect(mapStateToProps, null)(Structure);
