import React from "react";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import API from "../../../Utils/API";
import ReactSelect from "../../../Components/react-select/react-select";
import {
  getOptions,
  getRequest,
} from "../../../helper/forCountries/forCountries";
import "./EditEmployee.scss";
import {
  validationOnEmail,
  validationOnPassword,
  validationOnUsersName,
} from "../../../helper/validateHelper/validateHelper";
import Loader from "../../../Components/loader/loader";
import { EDIT_EMPLOYEE } from "../../../helper/forAddPartner/helperForAddPartner";

export default class EditEmployee extends React.Component {
  state = {
    ...EDIT_EMPLOYEE,
    id: this.props.id,
    disabled: false,
    incorrectText: false,
    successText: false,
    errorText: "",
  };
  componentDidMount() {
    getRequest("/api/v1/roles")
      .then((data) =>
        this.setState(
          {
            usersRole: data.data.data.filter(item => item.code !== 'SYSTEM_ADMIN'),
          },
          () =>
            getRequest(`/api/v1/employees/${this.state.id}`)
              .then((data) =>
                this.setState(
                  {
                    employeeUpdate: {
                      ...this.state.employeeUpdate,
                      email: data.data.email,
                      roleId: data.data.role.id,
                    },
                  },
                  () =>
                    this.setState({
                      ...this.state,
                      index: this.setSelectDefaultValue(),
                      loader: false,
                    })
                )
              )
              .catch((e) => console.log(e))
        )
      )
      .catch((e) => console.log(e));
  }
  setSelectDefaultValue = () => {
    const arr = [];
    this.state.usersRole.map((item) => arr.push(item.id));
    return arr.indexOf(this.state.employeeUpdate.roleId);
  };
  updateEmployee = async (id) => {
    try {
      this.setState({ disabled: true });
      await API().put(
        `/api/v1/employees/${Number(id)}`,
        this.state.employeeUpdate,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      this.setState({
        ...this.state,
        disabled: false,
        successText: true,
        employeeUpdate: {
          ...this.state.employeeUpdate,
          username: "",
          password: "",
          email: "",
          roleId: "",
        },
      });
    } catch (e) {
      this.setState({
        ...this.state,
        successText: false,
        incorrectText: true,
        disabled: false,
        errorText: e.response.data.message,
      });
    }
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        employeeUpdate: {
          ...this.state.employeeUpdate,
          [data]: e.target.value,
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  validateLength = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnPassword(this.state.employeeUpdate[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(
            this.state.employeeUpdate[data],
            length
          ),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateEmail = () => {
    this.setState(
      {
        ...this.state,
        email: {
          ...this.state.email,
          email: validationOnEmail(this.state.employeeUpdate.email),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isDisabledButton = () => {
    const state = this.state;
    return (
      state.email.email === false &&
      state.firstName.firstName === false &&
      state.surname.surname === false &&
      state.roleId.roleId === false
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  changeRole = (foo) => (selectedOption) => {
      this.setState(
        {
          ...this.state,
          employeeUpdate: {
            ...this.state.employeeUpdate,
            roleId: selectedOption ? selectedOption.value : "",
          },
        },
        () => {
          foo();
        }
      );
  };
  render() {
    let buttonType = "primary"
    if(this.state.disabled === false){
      buttonType = "secondary"
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            {this.state.usersRole.length && (
              <div className="modal-page">
                <span>Изменить данные пользователя</span>
                <div className="categories_bar">
                  <div className="active-header add">
                    <div>
                      <div className="input-height">
                        <Input
                          star={true}
                          onBlur={this.isTouched(
                            "surname",
                            this.validateUsersDataLength("surname", 3)
                          )}
                          text="Фамилия"
                          value={this.state.employeeUpdate.surname}
                          onChange={this.changeUserData(
                            "surname",
                            this.validateUsersDataLength("surname", 3)
                          )}
                          required={this.isRequired("surname")()}
                          validationMessage="Длина должна быть не менее 3 символов"
                        />
                      </div>
                      <div className="input-height">
                        <Input
                          star={true}
                          onBlur={this.isTouched(
                            "firstName",
                            this.validateUsersDataLength("firstName", 3)
                          )}
                          text="Имя"
                          value={this.state.employeeUpdate.firstName}
                          onChange={this.changeUserData(
                            "firstName",
                            this.validateUsersDataLength("firstName", 3)
                          )}
                          required={this.isRequired("firstName")()}
                          validationMessage="Длина должна быть не менее 3 символов"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="input-height">
                        <Input
                          star={true}
                          onBlur={this.isTouched(
                            "email",
                            this.validateEmail
                          )}
                          text="Email"
                          value={this.state.employeeUpdate.email}
                          onChange={this.changeUserData(
                            "email",
                            this.validateEmail
                          )}
                          required={this.isRequired("email")()}
                          validationMessage="Некорректный Email"
                        />
                      </div>
                      <div className="input-height">
                      <ReactSelect
                        star={true}
                        menuPlacement="top"
                        text="Роль"
                        placeholder="Выберите роль пользователя"
                        defaultValue={{
                          label: this.state.usersRole[this.state.index].name,
                          value: this.state.usersRole[this.state.index].id,
                        }}
                        onChange={this.changeRole(
                          this.validateUsersDataLength("roleId", 3)
                        )}
                        options={getOptions(this.state.usersRole)}
                        onBlur={this.isTouched(
                          "roleId",
                          this.validateUsersDataLength("roleId", 3)
                        )}
                        required={this.isRequired("roleId")()}
                        validationMessage="Поле не должно быть пустым"
                      />
                      </div>
                    </div>
                  </div>
                  <div className="btn-text-center">
                    <Button
                      variant={buttonType}
                      disabled={this.state.disabled}
                      onClick={() => this.updateEmployee(this.state.id)}
                    >
                      Сохранить
                    </Button>
                    {this.state.incorrectText && (
                      <h3 className="edit-wrong">
                        {this.state.errorText}
                      </h3>
                    )}
                    {this.state.successText && (
                      <h3 className="edit-correct">
                        Пользователь удачно изменен!
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
