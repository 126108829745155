import React from "react";
import { connect } from "react-redux";
import {
  getOptions,
  getRequest,
  sendRequest,
} from "../../helper/forCountries/forCountries";
import { DEFAULT_PAGINATION } from "../partners/AllPartners";
import Loader from "../../Components/loader/loader";
import ReactSelect from "../../Components/react-select/react-select";
import Button from "../../Components/Button/Button";
import { getPages } from "../../helper/pagination/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faDownload,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";

class BonusInfoOverall extends React.Component {
  state = {
    loader: false,
    mainLoader: true,
    incomeBody: {
      pageRequest: DEFAULT_PAGINATION,
      sorting: {
        sortBy: "WEEK",
        sortDirection: "ASC",
      },
      filter: {
        partnerId: "",
        type: null,
        week: "",
        countryId: "",
        regionId: "",
        cityId: "",
      },
    },
    searchPartner: {
      pageRequest: {
        page: 1,
        limit: 5,
      },
      filter: {
        searchParameter: "",
      },
    },
    partnerId: null,
    partnerList: [],
    incomeContent: {
      content: [],
    },
    accountType: this.props.meReducer.data.accountType,
    country: [],
    region: [],
    city: [],
    currentWeek: null,
    weeks: [],
    week: {},
    countryId: null,
    regionId: null,
    cityId: null,
    disabled: false,
  };
  componentDidMount() {
    getRequest(`/api/v1/weeks`)
      .then((data) =>
        this.setState(
          { ...this.state, currentWeek: data.data.currentWeek },
          () => this.weeksOptions()
        )
      )
      .catch((e) => console.log(e));
    if (this.state.accountType === "PARTNER") {
      this.sendRequestIncomePartner();
    } else if (this.state.accountType === "EMPLOYEE") {
      this.sendAdminBonusInfoOverall();
    }
  }
  sendAdminBonusInfoOverall = () => {
    sendRequest(`/api/v1/histories/admin/overall-income`, this.state.incomeBody)
      .then((data) =>
        this.setState(
          {
            ...this.state,
            incomeContent: data.data,
          },
          () => this.getCountries()
        )
      )
      .catch((e) => console.error(e));
  };
  searchPartners = () => {
    sendRequest(`/api/v1/admin/partners/search`, this.state.searchPartner)
      .then((data) =>
        this.setState({
          ...this.state,
          partnerList: data.data.content,
          loader: false,
        })
      )
      .catch((e) => console.log(e));
  };
  searchIncomeList = () => {
    let url = `/api/v1/histories/overall-income`;
    if (this.state.accountType === "EMPLOYEE") {
      url = `/api/v1/histories/admin/overall-income`;
    }
    this.setState({ ...this.state, loader: true });
    sendRequest(url, this.state.incomeBody)
      .then((data) =>
        this.setState({ ...this.state, incomeContent: data.data }, () =>
          this.setState({ ...this.state, loader: false })
        )
      )
      .catch((e) => console.error(e));
  };

  sendRequestIncomePartner = () => {
    sendRequest(`/api/v1/histories/overall-income`, this.state.incomeBody)
      .then((data) =>
        this.setState(
          {
            ...this.state,
            incomeContent: data.data,
          },
          () => this.getCountries()
        )
      )
      .catch((e) => console.error(e));
  };
  getIncomeList = () => {
    return this.state.incomeContent.content.map((item, index) => {
      return (
        <tr key={index}>
          <td data-label="Ф.И.О">
            <p>{item.partner.code}</p>
            <p>{item.partner.surname}</p>
            <p>{item.partner.firstName}</p>
            <p>{item.partner.patronymic}</p>
          </td>
          <td data-label="Неделя">{item.week}</td>
          <td data-label="Адрес">
            <div>
              {" "}
              <span>
                {item.partner.address.street !== null ? (
                  <span>
                    {item.partner.address.street !== "" ? (
                      <span>ул.</span>
                    ) : (
                      <span className="table-empty">-</span>
                    )}
                    {item.partner.address.street}{" "}
                  </span>
                ) : (
                  <span className="table-empty">-</span>
                )}
                {item.partner.address.house !== null && (
                  <span>{item.partner.address.house} </span>
                )}
              </span>
              <div>
                {item.partner.address.city !== null && (
                  <span>{item.partner.address.city.name} </span>
                )}
                {item.partner.address.region !== null && (
                  <span>{item.partner.address.region.name} </span>
                )}
                <div>
                  {item.partner.address.country !== null && (
                    <span>{item.partner.address.country.name} </span>
                  )}
                </div>
              </div>
            </div>
          </td>

          {this.state.incomeBody.filter.type !== "PARTNERSHIP_ACTIVATED" && (
            <td data-label="Бонус">
              {item.totalBonus ? (
                <span>
                  {item.totalBonus} <FontAwesomeIcon icon={faCoins} />
                </span>
              ) : (
                <span className="table-empty">-</span>
              )}
            </td>
          )}
        </tr>
      );
    });
  };
  weeksOptions = () => {
    let arr = [{ name: "Все недели", id: null }];
    for (let i = 1; i <= this.state.currentWeek; i += 1) {
      arr.push({ name: i, id: i });
    }
    this.setState({ ...this.state, weeks: arr });
  };
  handleInputChange = (e, { action }) => {
    if (action === "input-change") {
      this.setState(
        {
          ...this.state,
          incomeBody: {
            ...this.state.incomeBody,
            pageRequest: DEFAULT_PAGINATION,
            filter: { ...this.state.incomeBody.filter, partnerId: null },
          },
          searchPartner: {
            ...this.state.searchPartner,
            filter: {
              ...this.state.incomeBody.filter,
              searchParameter: e,
            },
          },
        },
        async () => this.searchPartners()
      );
    }
  };
  options = (data) => {
    return this.state[data].map((item) => {
      return {
        label: `${item.surname} ${item.firstName} ${item.code}`,
        value: item.id,
      };
    });
  };
  selectPartner = (e) => {
    this.setState(
      {
        ...this.state,
        loader: true,
        searchPartner: {
          ...this.state.searchPartner,
          filter: {
            ...this.state.searchPartner.filter,
            searchParameter: e ? e.label : null,
          },
        },
        incomeBody: {
          ...this.state.incomeBody,
          pageRequest: DEFAULT_PAGINATION,
          filter: {
            ...this.state.incomeBody.filter,
            partnerId: e ? e.value : null,
          },
        },
      },
      () => this.searchIncomeList()
    );
  };
  getCountries = () => {
    getRequest("/api/v1/common/countries")
      .then((data) =>
        this.setState({
          ...this.state,
          incomeBody: {
            ...this.state.incomeBody,
            pageRequest: DEFAULT_PAGINATION,
          },
          country: data.data.data,
          mainLoader: false,
        })
      )
      .catch((e) => console.error("error: ", e));
  };
  citiesOnRegion = () => {
    if(this.state.incomeBody.filter.regionId !== null) {
      getRequest(
        `/api/v1/common/cities/find-by-region/${this.state.incomeBody.filter.regionId}`,
        this.state.body
      ).then((data) =>
        this.setState({
          ...this.state,
          incomeBody: {
            ...this.state.incomeBody,
            pageRequest: DEFAULT_PAGINATION,
          },
          city: data.data.data,
        })
      );
    }
  };
  regionsOnCountry = () => {
    if(this.state.incomeBody.filter.countryId !== null) {
      getRequest(
        `/api/v1/common/regions/find-by-country/${this.state.incomeBody.filter.countryId}`,
        this.state.body
      ).then((data) =>
        this.setState({
          ...this.state,
          incomeBody: {
            ...this.state.incomeBody,
            pageRequest: DEFAULT_PAGINATION,
          },
          region: data.data.data,
        })
      );
    }
  };
  exportFile = () => {
    this.setState({ ...this.state, disabled: true });
    sendRequest(
      `/api/v1/histories/admin/overall-income/export`,
      {
        sorting: this.state.incomeBody.sorting,
        filter: this.state.incomeBody.filter,
      },
      "arraybuffer"
    )
      .then((res) => {
        let blob = new Blob([res.data], { type: "application/zip" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Бонус.xlsx";
        link.click();
        this.setState({ ...this.state, disabled: false });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ ...this.state, disabled: false });
      });
  };
  changeAddressId = (data, foo) => (e) =>  {
      this.setState(
        {
          ...this.state,
          [data]: {label:e ? e.label: null, value: e ? e.value : null},
          incomeBody: {
            ...this.state.incomeBody,
            pageRequest: DEFAULT_PAGINATION,
            filter: { ...this.state.incomeBody.filter, [data]: e ? e.value : null },
          },
          incorrectText: false,
          successText: false,
        },
        () => {
          if (data === "countryId") {
            this.setState({
              ...this.state,
              incomeBody: {
                ...this.state.incomeBody,
                filter: {
                  ...this.state.incomeBody.filter,
                  regionId: null,
                  cityId: null,
                },
              },
              region: [],
              city: [],
              regionId: null,
              cityId: null,
            });
          } else if (data === "regionId") {
            this.setState({
              ...this.state,
              incomeBody: {
                ...this.state.incomeBody,
                filter: {
                  ...this.state.incomeBody.filter,
                  cityId: null,
                },
              },
              city: [],
              cityId: null,
            });
          }
          if (foo !== null) {
            foo();
          }
        }
      );
  };
  changePage = (page) => () => {
    this.setState(
      {
        ...this.state,
        loader: true,
        incomeBody: {
          ...this.state.incomeBody,
          pageRequest: { page: page, limit: DEFAULT_PAGINATION.limit },
        },
      },
      () => this.searchIncomeList()
    );
  };
  sortBy = (value) => () => {
    if (value !== this.state.incomeBody.sorting.sortBy) {
      this.setState(
        {
          ...this.state,
          incomeBody: {
            ...this.state.incomeBody,
            sorting: { ...this.state.incomeBody.sorting, sortBy: value },
          },
        },
        () => this.searchIncomeList()
      );
    }
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <>
        {this.state.mainLoader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            {this.props.meReducer.data.accountType === "PARTNER" && (
              <div className="inactive-partnership">
                {this.props.meReducer.data.partnershipStatus !== "ACTIVE" && (
                  <span>
                    <b>У вас не активирован статус партнерства!</b>
                  </span>
                )}
              </div>
            )}
            <div className="categories_bar">
              <div className="filter-header">
                {this.state.accountType === "EMPLOYEE" && (
                  <div className="searchBlock">
                    <ReactSelect
                      value={this.state.incomeBody.filter.partnerId}
                      inputValue={
                        this.state.searchPartner.filter.searchParameter
                      }
                      filterOption={(option) => option}
                      placeholder="Найдите партнера..."
                      text="Выберите партнера"
                      onInputChange={this.handleInputChange}
                      onChange={this.selectPartner}
                      options={this.options("partnerList")}
                    />
                  </div>
                )}
                <div className="filter-block">
                  <div className="filter-select">
                    <div className="filter-select-height">
                      {" "}
                      <ReactSelect
                        filterOption={(option) => option}
                        text="Страна"
                        placeholder="Выберите страну"
                        onChange={this.changeAddressId(
                          "countryId",
                          this.regionsOnCountry
                        )}
                        options={getOptions(this.state.country)}
                      />
                    </div>
                    <div className="filter-select-height">
                      <ReactSelect
                        value={this.state.regionId}
                        text="Регион"
                        placeholder="Выберите регион"
                        onChange={this.changeAddressId(
                          "regionId",
                          this.citiesOnRegion
                        )}
                        options={getOptions(this.state.region)}
                      />
                    </div>
                    <div className="filter-select-height">
                      <ReactSelect
                        value={this.state.cityId}
                        text="Город"
                        placeholder="Выберите город"
                        onChange={this.changeAddressId("cityId", null)}
                        options={getOptions(this.state.city)}
                      />
                    </div>
                  </div>
                  <div className="filter-input">
                    <ReactSelect
                      text="Неделя"
                      placeholder="Выберите неделю"
                      onChange={this.changeAddressId("week", null)}
                      options={getOptions(this.state.weeks)}
                    />
                  </div>
                  <div className="filter-button">
                    <Button variant="blue" onClick={this.searchIncomeList}>
                      Поиск
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loader ? (
              <div className="loader-container">
                <Loader className="center" width="25px" height="25px" />
              </div>
            ) : (
              <>
                {this.state.incomeContent.content[0] && (
                  <>
                    {this.state.accountType === "EMPLOYEE" && (
                      <div className="export-button">
                        <Button
                          disabled={this.state.disabled}
                          variant={buttonType}
                          onClick={this.exportFile}
                        >
                          Экспорт <FontAwesomeIcon icon={faDownload} />
                        </Button>
                      </div>
                    )}
                    <div className="table-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">
                              Партнер{" "}
                              <span
                                className="cursor"
                                onClick={this.sortBy("PARTNER_CODE")}
                              >
                                <FontAwesomeIcon icon={faSortAmountUp} />
                              </span>
                            </th>
                            <th scope="col">
                              Неделя{" "}
                              <span
                                className="cursor"
                                onClick={this.sortBy("WEEK")}
                              >
                                <FontAwesomeIcon icon={faSortAmountUp} />
                              </span>
                            </th>
                            <th scope="col">Адрес</th>
                            <th scope="col">Бонус</th>
                          </tr>
                        </thead>
                        <tbody>{this.getIncomeList()}</tbody>
                      </table>
                    </div>
                    <div className="span-page">
                      {getPages(
                        this.state.incomeContent,
                        this.state.incomeBody.pageRequest.page,
                        this.changePage
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};
export default connect(mapStateToProps, null)(BonusInfoOverall);
