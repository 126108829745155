
export const DEFAULT_STATE_FOR_ADD_PARTNER = {
  searchProposer: {},
  searchMentor: {},
  body: {
    code: "",
    username: "",
    password: "",
    surname: "",
    firstName: "",
    patronymic: "",
    email: "",
    bankName: "",
    bankCardNumber: "",
    passport: {
      code: "",
      itn: "",
      issuedBy: "",
      issuedDate: new Date(),
    },
    address: {
      house: "",
      street: "",
      cityId: "",
      regionId: "",
      countryId: "",
    },
    proposerId: "",
    mentorId: "",
  },
  code: {
    code: "",
    touched: false,
  },
  username: {
    username: "",
    touched: false,
  },
  password: {
    password: "",
    touched: false,
  },
  surname: {
    surname: "",
    touched: false,
  },
  firstName: {
    firstName: "",
    touched: false,
  },
  email: {
    email: "",
    touched: false,
  },
  proposerId: {
    proposerId: "",
    touched: false,
  },
  mentorId: {
    mentorId: "",
    touched: false,
  },
  country: [],
  region: [],
  cities: [],
  proposers: [],
  mentors: [],
};
export const HELPER_FOR_EDIT_PARTNER = {
  loader: true,
  body: {
    type: "EMPLOYEE",
    code: "",
    username: "",
    surname: "",
    firstName: "",
    patronymic: "",
    email: "",
    bankName: "",
    bankCardNumber: "",
    passport: {
      code: "",
      itn: "",
      issuedBy: "",
      issuedDate: "",
    },
    address: {
      house: "",
      street: "",
      cityId: "",
      regionId: "",
      countryId: "",
    },
  },
  username: {
    username: false,
    touched: false,
  },
  surname: {
    surname: false,
    touched: false,
  },
  firstName: {
    firstName: false,
    touched: false,
  },
  code: {
    code: false,
    touched: false,
  },
  country: [],
  region: [],
  cities: [],
};
export const ADD_EMPLOYEE = {
  body: {
    username: "",
    password: "",
    email: "",
    surname: "",
    firstName: "",
    roleCode: "",
  },

  username: {
    touched: false,
    username: "",
  },
  password: {
    touched: false,
    password: "",
  },
  email: {
    touched: false,
    email: "",
  },
  surname: {
    touched: false,
    surname: "",
  },
  firstName: {
    touched: false,
    firstName: "",
  },
  roleCode: {
    touched: false,
    roleCode: "",
  },
  usersRole: [],
}
export const EDIT_EMPLOYEE = {
  loader: true,
  index: null,
  usersRole: [],
  firstName: {
    firstName: "",
    touched: false,
  },
  surname: {
    surname: "",
    touched: false,
  },
  email: {
    email: false,
    touched: false,
  },
  roleId: {
    roleId: false,
    touched: false,
  },
  employeeUpdate: {
    firstName: "",
    surname: "",
    email: "",
    status: "ACTIVE",
    roleId: null,
  },
};