import React from "react";
import {
  getRequest,
  sendRequest,
} from "../../helper/forCountries/forCountries";
import Loader from "../../Components/loader/loader";
import Button from "../../Components/Button/Button";

export default class PurchaseAll extends React.Component {
  state = {
    body: {
      purchases: [],
    },
    purchases: [],
    loader: true,
    id: this.props.id,
    content: [],
    disabled: true,
    incorrectText: false,
    successText: false,
    errorText: "",
  };
  componentDidMount() {
    this.allProducts();
  }
  total = 0;
  allProducts = () => {
    getRequest(`/api/v1/store/products/find-all`).then((data) =>
      this.setState({ ...this.state, content: data.data.data }, () => {
        let arr = [];
        this.state.content.map((item) => {
          arr.push({ productId: item.id, quantity: 0 });
          return this.setState({ ...this.state, purchases: arr }, () =>
            this.setState({ ...this.state, disabled: true, loader: false })
          );
        });
      })
    );
  };
  changeQuantity = (selectedId) => (e) => {
    let disabled = true;
    if (e.target.value >= 0) {
      const purchaseCopy = [...this.state.purchases];
      purchaseCopy.forEach((p) => {
        if (p.productId === selectedId) {
          p.quantity = e.target.value;
        }
        if (p.quantity > 0) {
          disabled = false;
        }
      });
      this.setState((prev) => ({ ...prev, purchases: [...purchaseCopy] }));
      this.setState({
        ...this.state,
        incorrectText: false,
        successText: false,
        disabled: disabled,
      });
    }
  };
  productList = () => {
    let total = 0;
    return this.state.content.map((item, index) => {
      total = total + item.price * this.state.purchases[index].quantity;
      this.total = total;
      return (
        <tr key={index}>
          <td data-label="Продукт">{item.name}</td>
          <td data-label="Цена">{item.price}</td>
          <td data-label="Кол-во">
            <input
              type="number"
              className="purchase-input"
              onChange={this.changeQuantity(item.id)}
            />
          </td>
          <td data-label="Итого">
            {item.price * this.state.purchases[index].quantity}
          </td>
        </tr>
      );
    });
  };
  getTotalPrice = () => {
    return <span>Итого: {this.total}</span>;
  };
  buyItems = () => {
    let arr = [...this.state.purchases];
    const result = arr.filter((item) => item.quantity > 0);
    this.setState({ ...this.state, body: { purchases: result } }, () =>
      sendRequest(
        `/api/v1/admin/partners/${this.props.id}/purchase-multiple`,
        this.state.body
      )
        .then(() => {
          this.setState(
            {
              ...this.state,
              loader: true,
              incorrectText: false,
              successText: true,
            },
            () => this.allProducts()
          );
        })
        .catch((e) => {
          this.setState({
            ...this.state,
            incorrectText: true,
            successText: false,
            errorText: e.response.data.message
          });
        })
    );
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="15px" height="15px" />
          </div>
        ) : (
          <div>
            <div className="modal-page purchase-height">
              <div className="categories_bar">
                <div className="table-wrapper-purchase">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Товар</th>
                        <th scope="col">Цена</th>
                        <th scope="col">Кол-во</th>
                        <th scope="col">Итого</th>
                      </tr>
                    </thead>
                    <tbody>{this.productList()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="total-info">
              {this.getTotalPrice()}
              <div>
                {this.state.incorrectText && (
                  <span className="error-message">{this.state.errorText}</span>
                )}{" "}
                {this.state.successText && (
                  <span className="success-message">Успешно</span>
                )}
              </div>
              <Button
                disabled={this.state.disabled}
                variant={buttonType}
                onClick={this.buyItems}
              >
                Купить
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
}
