import React from "react";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import { sendRequest } from "../../../helper/forCountries/forCountries";
import { validationOnUsersName } from "../../../helper/validateHelper/validateHelper";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class AddCategory extends React.Component {
  state = {
    body: {
      name: "",
    },
    disabled: false,
    name: "",
    successText: false,
    incorrectText: false,
    errorText: "",
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: validationOnUsersName(this.state.body[data], length),
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  sendRequest = () => {
    sendRequest("/api/v1/store/product-categories", this.state.body)
      .then(() =>
        this.setState(
          {
            ...this.state,
            successText: true,
            body: { ...this.state, name: "" },
          },
          () =>
            setTimeout(
              () => this.setState({ ...this.state, successText: false }),
              4000
            )
        )
      )
      .catch((e) =>
        this.setState({
          ...this.state,
          incorrectText: true,
          successText: false,
          errorText: e.response.data.message,
        })
      );
  };
  isDisabledButton = () => {
    return this.state.name === false;
  };
  render() {
    let buttonType = "primary"
    if(this.state.disabled === false){
      buttonType = "secondary"
    }
    return (
      <>
        <div className="modal-page">
          <span>Добавить категорию</span>
          <div className="categories_bar">
            <Input
              star={true}
              text="Название категории продукции"
              value={this.state.body.name}
              onChange={this.changeUserData(
                "name",
                this.validateUsersDataLength("name", 1)
              )}
              onBlur={this.validateUsersDataLength("name", 1)}
              required={this.state.name}
              validationMessage="Поле не должно быть пустым"
            />
            <div className="active-header">
              <Button
                variant={buttonType}
                disabled={this.state.disabled}
                onClick={this.sendRequest}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Добавить
              </Button>
              {this.state.incorrectText && (
                <span className="error-message">
                  {this.state.errorText}
                </span>
              )}
              {this.state.successText && (
                <span className="success-message">
                  Категория успешно добавлена
                </span>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
