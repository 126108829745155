import React from "react";
import { getRequest } from "../../../helper/forCountries/forCountries";
import Button from "../../../Components/Button/Button";
import AddCity from "./addCity";
import EditCity from "./editCity";
import Modal from "react-modal";
import Loader from "../../../Components/loader/loader";
import { faBuilding, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortFn } from "../../../helper/sort";
import { connect } from "react-redux";

class AllCities extends React.Component {
  state = {
    region: "",
    cities: [],
    changeModal: false,
    addModal: false,
    id: "",
    loader: true,
  };
  componentDidMount() {
    this.getRequest();
    getRequest(`/api/v1/common/regions/${this.props.match.params.id}`)
      .then((data) => this.setState({ ...this.state, region: data.data.name }))
      .catch((e) => console.log(e));
  }
  getRequest = () => {
    getRequest(
      `/api/v1/common/cities/find-by-region/${this.props.match.params.id}`
    )
      .then((data) =>
        this.setState({
          ...this.state,
          cities: sortFn(data.data.data),
          loader: false,
        })
      )
      .catch((e) => console.log(e));
  };
  changeId = (id) => () => {
    this.setState({ ...this.state, id: id }, () => this.editCityModal());
  };
  listAllCities = () =>
    this.state.cities.map((item) => {
      return (
        <tr key={item.id}>
          <td data-label="ID Страны">
            <b>{item.region.country.id}.</b>
          </td>
          <td data-label="Название страны">{item.region.country.name}</td>
          <td data-label="ID региона">
            <b>{item.region.id}.</b>
          </td>
          <td data-label="Название региона">{item.region.name}</td>
          <td data-label="ID">
            <b>{item.id}</b>
          </td>
          <td data-label="Название">
            <b>{item.name}</b>
          </td>
          <td>
            {this.props.meReducer.data.role === "ADMIN" && (
              <Button variant="secondary" onClick={this.changeId(item.id)}>
                <FontAwesomeIcon icon={faPen} /> изменить
              </Button>
            )}
          </td>
        </tr>
      );
    });
  closeEditCityModal = (data) => () => {
    this.setState({ ...this.state, [data]: false }, () => this.getRequest());
  };
  editCityModal = () => {
    this.setState({ ...this.state, changeModal: true });
  };
  openCityModal = () => {
    this.setState({ ...this.state, addModal: true });
  };
  render() {
    const id = this.props.match.params.id;
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            <div className="categories_bar">
              <div className="active-header">
                <div className="active-header-button">
                  <h2>{this.state.region}</h2>
                </div>
                <div className="active-header-button">
                  <Button variant="blue" onClick={this.openCityModal}>
                    <FontAwesomeIcon icon={faBuilding} /> Добавить город
                  </Button>
                </div>
              </div>
            </div>
            {!!this.state.cities[0] ? (
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">ID страны</th>
                      <th scope="col">Название страны</th>
                      <th scope="col">ID региона</th>
                      <th scope="col">Название региона</th>
                      <th scope="col">ID города</th>
                      <th scope="col">Название города</th>
                      <th scope="col">Действия</th>
                    </tr>
                  </thead>
                  <tbody>{this.listAllCities()}</tbody>
                </table>
              </div>
            ) : (
              <p>
                <b>Отсутствует список городов, возможно вы не добавили их</b>
              </p>
            )}
            <Modal
              className="modal"
              overlayClassName="overlay"
              isOpen={this.state.addModal}
              onRequestClose={this.closeEditCityModal("addModal")}
            >
              <span
                className="exit-span"
                onClick={this.closeEditCityModal("addModal")}
              >
                X
              </span>
              <AddCity id={id} />
            </Modal>
            <Modal
              className="modal"
              overlayClassName="overlay"
              isOpen={this.state.changeModal}
              onRequestClose={this.closeEditCityModal("changeModal")}
            >
              <span
                className="exit-span"
                onClick={this.closeEditCityModal("changeModal")}
              >
                X
              </span>
              <EditCity id={this.state.id} />
            </Modal>
          </>
        )}
      </>
    );
  }
}
AllCities.displayName = "AllCities";
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};

export default connect(mapStateToProps, null)(AllCities);
