import React from "react";
import './index.scss';

export default function Button(props) {
  const {variant = "primary"} = props
  return (
    <button
      type={props.type}
      className={`${props.className} ${variant}`}
      onClick={props.onClick}
      disabled={props.disabled}
      onFocus={props.focus}
    >
      {props.children}
    </button>
  );
}
