import API from "../../Utils/API";

export const getRequest = async (url) => {
  try {
    return await API().get(url);
  } catch (e) {
    console.error("error: ", e);
  }
};
export const sendRequest = async (url, body, responseType) => {
  try {
    return await API(responseType).post(url, body, {
      headers: { "Content-Type": "application/json" },
    });
  } catch (e) {
    throw e;
  }
};
export const sendRequestToEdit = async (url, body) => {
  try {
    return await API().put(url, body, {
      headers: { "Content-Type": "application/json" },
    });
  } catch (e) {
    throw e;
  }
};
export const getOptions = (data) => {
  return data.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
};
