import React from "react";
import {
  getRequest,
  sendRequest,
} from "../../helper/forCountries/forCountries";
import Button from "../../Components/Button/Button";
import Input from "../../Components/input/Input";
import AddProduct from "./addProduct";
import { DEFAULT_PAGINATION } from "../partners/AllPartners";
import EditCategory from "../product-categories/edit-category/editCategory";
import EditProduct from "./editProduct";
import Loader from "../../Components/loader/loader";
import {
  faCartPlus, faCoins,
  faPen,
  faPlusCircle, faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPages } from "../../helper/pagination/pagination";
import Modal from "react-modal";

export default class Products extends React.Component {
  state = {
    loader: true,
    changeEditCategoryModal: false,
    changeModal: false,
    addModal: false,
    id: 1,
    categoryName: "",
    body: {
      pageRequest: {
        page: 1,
        limit: 10,
      },
      sorting: {
        sortBy: "ID",
        sortDirection: "ASC",
      },
      filter: {
        categoryId: null,
      },
    },
    content: {
      page: null,
      numberOfElements: null,
      totalPages: null,
      totalElements: null,
      content: [],
    },
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.body.filter.categoryId === null ||
      prevProps.id !== this.props.id
    ) {
      this.setState(
        {
          ...this.state,
          body: {
            ...this.state.body,
            pageRequest: DEFAULT_PAGINATION,
            filter: { ...this.state.body.filter, categoryId: this.props.id },
          },
        },
        () => {
          this.sendRequest();
          getRequest(
            `/api/v1/store/product-categories/${this.state.body.filter.categoryId}`
          ).then((data) =>
            this.setState({
              ...this.state,
              categoryName: data.data.name,
            })
          );
        }
      );
    }
  }
  sendRequest = () => {
    sendRequest("/api/v1/store/products/search", this.state.body)
      .then((data) =>
        this.setState({ ...this.state, loader: false, content: data.data })
      )
      .catch((e) => console.log(e));
  };
  closeEditCityModal = (data) => () => {
    this.setState({ ...this.state, [data]: false }, () => this.sendRequest());
  };
  openModal = () => {
    this.setState({ ...this.state, addModal: true });
  };
  productsList = () => {
    if (this.state.content.content) {
      return this.state.content.content.map((item) => {
        return (
          <tr key={item.id}>
            <td data-label="Название">{item.name}</td>
            <td data-label="Цена">{item.price} <FontAwesomeIcon icon={faCoins} /></td>
            {this.props.data.accountType === "EMPLOYEE" &&
            this.props.data.role === "ADMIN" &&
                <td data-label="Действия">
                  <Button
                    variant="secondary"
                    onClick={this.editProduct(item.id)}
                  >
                    <FontAwesomeIcon icon={faPen} /> Изменить
                  </Button>
                </td>
              }
          </tr>
        );
      });
    }
  };
  changePage = (page) => () => {
    this.setState(
      {
        ...this.state,
        body: {
          ...this.state.body,
          pageRequest: { page: page, limit: 10 },
        },
      },
      () =>
        sendRequest("/api/v1/store/products/search", this.state.body)
          .then((data) => this.setState({ ...this.state, content: data.data }))
          .catch((e) => console.error("error: ", e))
    );
  };
  changeStateData = (e) => {
    this.setState(
      {
        ...this.state,
        body: {
          ...this.state.body,
          pageRequest: { page: 1, limit: 10 },
          filter: {
            searchParameter: e.target.value,
            categoryId: this.props.id,
          },
        },
      },
      () =>
        sendRequest("/api/v1/store/products/search", this.state.body)
          .then((data) =>
            this.setState({
              content: data.data,
            })
          )
          .catch((e) => console.error("Error: ", e))
    );
  };
  editCategoryModal = () => {
    this.setState({ ...this.state, changeEditCategoryModal: true });
  };
  editProduct = (id) => () =>
    this.setState({ ...this.state, changeModal: true, id: id });
  sortBy = (value) => () => {
    if (value !== this.state.body.sorting.sortBy) {
      this.setState(
        {
          ...this.state,
          body: {
            ...this.state.body,
            sorting: { ...this.state.body.sorting, sortBy: value },
          },
        },
        () => this.sendRequest()
      );
    }
  };
  render() {
    return (
      <>
        <div className="active-header">
          <div className="active-header-button">
            <h2>Категория: {this.state.categoryName}</h2>
          </div>
          {this.props.data.accountType === "EMPLOYEE" &&
          this.props.data.role === "ADMIN" &&
              <div className="active-header-button">
                <Button onClick={this.editCategoryModal} variant="blue">
                  <FontAwesomeIcon icon={faPen} /> изменить категорию
                </Button>
              </div>
            }
        </div>
        <div className="categories_bar">
          <div className="active-header">
            <Input
              text="Найти"
              placeholder="Поиск..."
              variant="search"
              onChange={this.changeStateData}
            />
            {this.props.data.accountType === "EMPLOYEE" &&
            this.props.data.role === "ADMIN" &&
                <div className="active-header-button">
                  <Button variant="blue" onClick={this.openModal}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <FontAwesomeIcon icon={faCartPlus} /> Добавить продукт
                  </Button>
                </div>
              }
          </div>
        </div>
        <>
          {this.state.loader ? (
            <div className="loader-container">
              <Loader className="center" width="25px" height="25px" />
            </div>
          ) : (
            <>
              {this.state.content.content.length > 0 ? (
                <>
                  <div className="table-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Наименование <span
                            className="cursor"
                            onClick={this.sortBy("NAME")}
                          >
                                <FontAwesomeIcon icon={faSortAmountUp} />
                              </span></th>
                          <th scope="col">Цена <span
                            className="cursor"
                            onClick={this.sortBy("PRICE")}
                          >
                                <FontAwesomeIcon icon={faSortAmountUp} />
                              </span></th>
                          {this.props.data.accountType === "EMPLOYEE" && this.props.data.role === "ADMIN" &&
                              <th scope="col">Действия</th>
                            }
                        </tr>
                      </thead>
                      <tbody>{this.productsList()}</tbody>
                    </table>
                  </div>
                  <div className="span-page">
                    {getPages(
                      this.state.content,
                      this.state.body.pageRequest.page,
                      this.changePage
                    )}
                  </div>
                </>
              ) : (
                <span className="error-message">Список пока пуст</span>
              )}
            </>
          )}
        </>
        <Modal
          className="modal"
          overlayClassName="overlay"
          isOpen={this.state.addModal}
          onRequestClose={this.closeEditCityModal("addModal")}
        >
          <span
            className="exit-span"
            onClick={this.closeEditCityModal("addModal")}
          >
            X
          </span>
          <AddProduct id={this.props.id} />
        </Modal>
        <Modal
          className="modal"
          overlayClassName="overlay"
          isOpen={this.state.changeEditCategoryModal}
          onRequestClose={this.closeEditCityModal("changeEditCategoryModal")}
        >
          <span
            className="exit-span"
            onClick={this.closeEditCityModal("changeEditCategoryModal")}
          >
            X
          </span>
          <EditCategory id={this.props.id} />
        </Modal>
        <Modal
          className="modal"
          overlayClassName="overlay"
          isOpen={this.state.changeModal}
          onRequestClose={this.closeEditCityModal("changeModal")}
        >
          <span
            className="exit-span"
            onClick={this.closeEditCityModal("changeModal")}
          >
            X
          </span>
          <EditProduct id={this.state.id} categoryId={this.props.id} />
        </Modal>
      </>
    );
  }
}
