import React from "react";
import Select from "react-select";

const customStyles = {
  menu: provided => ({ ...provided, zIndex: 1000 }),
  container: (base, state) => ({
    ...base,
    border: state.isFocused ? null : null,
    transition:
      "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
    "&:hover": {
      boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
    },
    width: "95%",
    marginTop: "10px",
  }),
  control: (base, state) => ({
    ...base,
    background: "rgba(255,255,255,0.93)",
    border: state.isFocused ? "none" : "1px solid gray",
    borderBottom: state.isFocused ? "1px solid blue" : "1px solid gray",
    outline: "none",
    boxShadow: "none",
  }),
  valueContainer: (base, state) => ({
    ...base,
    background: "white",
  }),
  multiValue: (base, state) => ({
    ...base,
    background: "lightYellow",
    maxWidth: "95%",
  }),
};

export default function ReactSelect(props) {
  return (
    <div className={props.className}>
      <label className="label">
        {props.text}
        {props.star === true && <span className="require">*</span>}
      </label>
      <Select
        menuIsOpen={props.menuIsOpen}
        className="react-select--editable"
        classNamePrefix="react-select"
        backspaceRemovesValue={true}
        isClearable={true}
        filterOption={props.filterOption}
        maxMenuHeight={props.maxMenuHeight}
        captureMenuScroll={props.captureMenuScroll}
        isLoading={props.isLoading}
        isSearchable={props.isSearchable}
        inputValue={props.inputValue}
        menuPlacement={props.menuPlacement}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={props.value}
        textFieldProps={props.textFieldProps}
        onInputChange={props.onInputChange}
        onChange={props.onChange}
        options={props.options}
        styles={customStyles}
        onBlur={props.onBlur}
        required={props.required}
        onFocus={props.onFocus}
        onClick={props.onClick}
        hasValue={props.hasValue}
      />
      {props.required === true && (
        <span className="validation-message">{props.validationMessage}</span>
      )}
    </div>
  );
}
