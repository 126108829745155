import React, {useState} from 'react'
import API from "../../Utils/API";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom'

const Import = props => {

  const [errorMessage, setErrorMessage] = useState(null)
  const [loader, setLoader] = useState(false)

  if (props.meReducer.data.role !== 'SYSTEM_ADMIN') {
    return <Redirect to='/'/>
  }

  const onUpload = async (event) => {
    setErrorMessage(null)
    if (event.target.files.length) {
      const formData = new FormData()
      formData.append('file', event.target.files[0])
      try {
        setLoader(true)
        await API().post('/api/v1/system/import/excel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        await onStartNewWeek()
        setLoader(false)
      } catch (error) {
        setErrorMessage(error.response.data.message)
        console.error(error.response.data.message, 'ERROR WHILE UPLOADING FILE')
        setLoader(false)
      }
    }
  }

  const onStartNewWeek = async () => {
    try {
      setLoader(true)
      await API().post('/api/v1/system/start-new-week', {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      setLoader(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      console.error(error.response.data.message, 'ERROR WHILE UPLOADING FILE')
      setLoader(false)
    }
  }

  return (
    <div>
      <input
        disabled={loader}
        onChange={onUpload}
        accept='xlsx'
        type="file"/>
      {
        errorMessage &&
        <p style={{color: 'red'}}>{errorMessage}</p>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};
export default connect(mapStateToProps, {})(Import)