export const arrowFn = (data, id) => {
  if (data.rightPartner !== null && data.leftPartner !== null) {
    return [
      {
        targetId: `left-${id}`,
        targetAnchor: "top",
        sourceAnchor: "bottom",
      },
      {
        targetId: `right-${id}`,
        targetAnchor: "top",
        sourceAnchor: "bottom",
      },
    ];
  }
  else if(data.rightPartner !== null && data.leftPartner === null) {
    return [
      {
        targetId: `right-${id}`,
        targetAnchor: "top",
        sourceAnchor: "bottom",
      }
    ]
  }
  else if(data.rightPartner === null && data.leftPartner !== null) {
    return [
      {
        targetId: `left-${id}`,
        targetAnchor: "top",
        sourceAnchor: "bottom",
      }
    ]
  }
};
