import React from "react";
import AddCategory from "./add-category/addCategory";
import Button from "../../Components/Button/Button";
import { getOptions, getRequest } from "../../helper/forCountries/forCountries";
import "./index.scss";
import Products from "../products/products";
import ReactSelect from "../../Components/react-select/react-select";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortFn } from "../../helper/sort";
import { connect } from "react-redux";
import Modal from "react-modal";

class ProductCategories extends React.Component {
  state = {
    categories: [],
    id: 1,
    addModal: false,
  };
  componentDidMount() {
    this.getRequest();
  }
  getRequest = () => {
    getRequest("/api/v1/store/product-categories/find-all").then((data) =>
      this.setState({
        ...this.state,
        categories: sortFn(data.data.data),
      })
    );
  };
  closeEditModal = (data) => () => {
    this.setState({ ...this.state, [data]: false }, () => this.getRequest());
  };
  openModal = () => {
    this.setState({ ...this.state, addModal: true });
  };
  changeCategory = (e) => {
    if(e) {
      this.setState({...this.state, id: e.value});
    }};
  render() {
    return (
      <>
        {this.props.meReducer.data.accountType === "PARTNER" && (
          <div className="inactive-partnership">
            {this.props.meReducer.data.partnershipStatus !== "ACTIVE" && (
              <span><b>У вас не активирован статус партнерства!</b></span>
            )}
          </div>
        )}
        <div className="active-header">
          <div className="active-header-button">
            <h2>Категории всего {this.state.categories.length}</h2>
          </div>
          {this.props.meReducer.data.accountType === "EMPLOYEE" &&
          this.props.meReducer.data.role === "ADMIN" &&
              <div className="active-header-button">
                <Button variant="blue" onClick={this.openModal}>
                  <b>
                    <FontAwesomeIcon icon={faPlusCircle} /> Добавить категорию
                  </b>
                </Button>
              </div>
            }
        </div>
        {this.state.categories.length > 0 && (
          <div className="categories_bar">
            <div className="react-select-position">
              <ReactSelect
                defaultValue={{
                  label: this.state.categories[0].name,
                  value: this.state.categories[0].id,
                }}
                text="Категории"
                onChange={this.changeCategory}
                options={getOptions(this.state.categories)}
              />
            </div>
          </div>
        )}
        <Products id={this.state.id} data={this.props.meReducer.data} />
        <Modal
          className="modal"
          overlayClassName="overlay"
          isOpen={this.state.addModal}
          onRequestClose={this.closeEditModal("addModal")}
        >
          <span className="exit-span" onClick={this.closeEditModal("addModal")}>X</span>
          <AddCategory />
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};

export default connect(mapStateToProps, null)(ProductCategories);
