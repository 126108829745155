import React from "react";
import Button from "../../../Components/Button/Button";
import { Link } from "react-router-dom";
import "./style.scss";
import AddCountry from "./addCountry";
import EditCountry from "./editCountry";
import "../../partners/AllPartners.scss";
import { getRequest } from "../../../helper/forCountries/forCountries";
import Loader from "../../../Components/loader/loader";
import Modal from "react-modal";
import {
  faCompass,
  faGlobeAsia,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortFn } from "../../../helper/sort";
import { connect } from "react-redux";

class AllCountry extends React.Component {
  state = {
    countries: [],
    changeModal: false,
    addModal: false,
    id: "",
    loader: true,
  };
  componentDidMount() {
    this.getRequest();
  }
  getRequest = () => {
    getRequest("/api/v1/common/countries")
      .then((data) =>
        this.setState({
          ...this.state,
          countries: sortFn(data.data.data),
          loader: false,
        })
      )
      .catch((e) => console.error("error: ", e));
  };
  changeId = (id) => () => {
    this.setState({ ...this.state, id: id }, () => this.editModal());
  };
  listAllCountries = () => {
    return this.state.countries.map((item) => {
      return (
        <tr key={item.id}>
          <td data-label="ID">
            <b>{item.id}.</b>
          </td>
          <td data-label="Название">
            <b>{item.name}</b>
          </td>
          <td data-label="Действия">
            <Link to={`/users/all-regions/${item.id}`}>
              <Button variant="blue">
                <FontAwesomeIcon icon={faCompass} />
                перейти к регионам
              </Button>
            </Link>
            {this.props.meReducer.data.role === "ADMIN" && (
              <Button variant="secondary" onClick={this.changeId(item.id)}>
                <FontAwesomeIcon icon={faPen} />
                изменить
              </Button>
            )}
          </td>
        </tr>
      );
    });
  };
  closeEditModal = (data) => () => {
    this.setState({ ...this.state, [data]: false }, () => this.getRequest());
  };
  editModal = () => {
    this.setState({ ...this.state, changeModal: true });
  };
  openModal = () => {
    this.setState({ ...this.state, addModal: true });
  };
  render() {
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            <div className="categories_bar">
              <div className="active-header">
                <div className="active-header-button">
                  <h3>Всего стран {this.state.countries.length}</h3>
                </div>
                <div className="active-header-button">
                  <Button variant="blue" onClick={this.openModal}>
                    <span>
                      <FontAwesomeIcon icon={faGlobeAsia} /> Добавить страну
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            {this.state.countries[0] ? (
              <>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col"> Название страны</th>
                        <th scope="col">Действия</th>
                      </tr>
                    </thead>
                    <tbody>{this.listAllCountries()}</tbody>
                  </table>
                </div>
                <Modal
                  className="modal"
                  overlayClassName="overlay"
                  isOpen={this.state.addModal}
                  onRequestClose={this.closeEditModal("addModal")}
                >
                  <span
                    className="exit-span"
                    onClick={this.closeEditModal("addModal")}
                  >
                    X
                  </span>
                  <AddCountry />
                </Modal>
                <Modal
                  className="modal"
                  overlayClassName="overlay"
                  isOpen={this.state.changeModal}
                  onRequestClose={this.closeEditModal("changeModal")}
                >
                  <span
                    className="exit-span"
                    onClick={this.closeEditModal("changeModal")}
                  >
                    X
                  </span>
                  <EditCountry id={this.state.id} />
                </Modal>
              </>
            ) : (
              <p>Отсутствует список, добавьте их</p>
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};

export default connect(mapStateToProps, null)(AllCountry);
