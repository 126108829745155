import React from "react";
import "./input.scss";

export default function Input(props) {
  const {variant = "basic"} = props
  return (
    <div className={`${props.className} ${variant}`}>
      <label>{props.text}{props.star === true &&<span className="require">*</span>}</label>
      <input
        onKeyPress={props.onKeyPress}
        autoComplete={props.autoComplete}
        type={props.type}
        min={props.min}
        max={props.max}
        value={props.value}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        ref={props.inputRef}
        disabled={props.disabled}
        onChange={props.onChange}
        maxLength={props.maxLength}
        minLength={props.minLength}
        placeholder={props.placeholder}
        required={props.required}
      />
      <span className="validation-message">
        {props.required === true && <span>{props.validationMessage}</span>}
      </span>
    </div>
  );
}
