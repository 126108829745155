import React from "react";
import Input from "../../../Components/input/Input";
import Button from "../../../Components/Button/Button";
import { sendRequest } from "../../../helper/forCountries/forCountries";
import {
  validationForNumeric,
  validationOnUsersName,
} from "../../../helper/validateHelper/validateHelper";

export default class AddCity extends React.Component {
  id = this.props.id;
  state = {
    body: {
      name: "",
      key: "",
      regionId: this.id,
    },
    name: {
      name: "",
      touched: false,
    },
    key: {
      key: "",
      touched: false,
    },
    disabled: true,
    incorrectText: false,
    successText: false,
    errorText: "",
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  isSendRequest = (url, body) => () => {
    return sendRequest(url, body)
      .then(() =>
        this.setState({ ...this.state, disabled: true, successText: true })
      )
      .catch((e) =>
        this.setState({
          ...this.state,
          incorrectText: true,
          errorText: e.response.data.message,
        })
      );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(this.state.body[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateForNumeric = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationForNumeric(this.state.body[data]),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  isDisabledButton = () => {
    const state = this.state;
    return state.name.name === false && state.key.key === false;
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <div className="modal-page">
        <span>Добавить город</span>
        <div className="categories_bar">
          <Input
            star={true}
            text="Название города"
            value={this.state.body.name}
            onChange={this.changeUserData(
              "name",
              this.validateUsersDataLength("name", 1)
            )}
            onBlur={this.isTouched(
              "name",
              this.validateUsersDataLength("name", 1)
            )}
            required={this.isRequired("name")()}
            validationMessage="Поле не должно быть пустым"
          />
          <Input
            star={true}
            text="ID города"
            value={this.state.body.key}
            onChange={this.changeUserData(
              "key",
              this.validateForNumeric("key")
            )}
            onBlur={this.isTouched("key", this.validateForNumeric("key"))}
            required={this.isRequired("key")()}
            validationMessage="Поле не должно быть пустым и содержать только цифру(ы)"
          />
          <div className="active-header">
            <Button
              disabled={this.state.disabled}
              variant={buttonType}
              onClick={this.isSendRequest(
                "/api/v1/common/cities",
                this.state.body
              )}
            >
              Добавить
            </Button>
            {this.state.incorrectText && (
              <span className="error-message">{this.state.errorText}</span>
            )}
            {this.state.successText && (
              <span className="success-message">Успешно добавлен</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
