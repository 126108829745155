import React from "react";
import {
  getRequest,
  sendRequestToEdit,
} from "../../helper/forCountries/forCountries";
import Input from "../../Components/input/Input";
import {
  validationForNumeric,
  validationOnUsersName,
} from "../../helper/validateHelper/validateHelper";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/loader/loader";

export default class EditProduct extends React.Component {
  state = {
    loader: true,
    body: {
      name: "",
      price: "",
      code: "",
      categoryId: this.props.categoryId,
    },
    disabled: true,
    name: false,
    price: false,
    code: false,
    successTest: false,
    incorrectText: false,
    errorText: "",
  };
  componentDidMount() {
    this.getRequest();
  }
  getRequest = () => {
    getRequest(`/api/v1/store/products/${this.props.id}`).then((data) =>
      this.setState({
        ...this.state,
        loader: false,
        body: {
          ...this.state.body,
          name: data.data.name,
          price: data.data.price,
          code: data.data.code,
        },
      })
    );
  };
  sendRequest = () => {
    sendRequestToEdit(`/api/v1/store/products/${this.props.id}`, this.state.body)
      .then(() => this.setState({ ...this.state, successText: true }))
      .catch((e) =>
        this.setState({
          ...this.state,
          incorrectText: true,
          successText: false,
          errorText: e.response.data.message,
        })
      );
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: validationOnUsersName(this.state.body[data], length),
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateForNumeric = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: validationForNumeric(this.state.body[data]),
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isDisabledButton = () => {
    const state = this.state;
    return (state.name === false && state.price === false);
  };
  render() {
    let buttonType = "primary"
    if(this.state.disabled === false){
      buttonType = "secondary"
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            <div className="modal-page">
              <span>Изменить продукцию</span>
              <div className="categories_bar">
                <Input
                  star={true}
                  text="Название товара"
                  placeholder="Введите название товара"
                  value={this.state.body.name}
                  onChange={this.changeUserData(
                    "name",
                    this.validateUsersDataLength("name", 1)
                  )}
                  onBlur={this.validateUsersDataLength("name", 1)}
                  required={this.state.name}
                  validationMessage="Поле не должно быть пустым"
                />
                <Input
                  star={true}
                  text="Цена товара"
                  placeholder="Введите цену товара"
                  value={this.state.body.price}
                  onChange={this.changeUserData(
                    "price",
                    this.validateForNumeric("price")
                  )}
                  onBlur={this.validateForNumeric(this.state.body.price)}
                  required={this.state.price}
                  validationMessage="Поле не должно быть пустым"
                />
                <Input
                  star={true}
                  text="Код товара"
                  placeholder="Введите код товара"
                  value={this.state.body.code}
                  onChange={this.changeUserData(
                    "code",
                    this.validateForNumeric("code")
                  )}
                  onBlur={this.validateForNumeric(this.state.body.code)}
                  required={this.state.code}
                  validationMessage="Поле не должно быть пустым и должен содержать только цифры"
                />
                <div className="active-header">
                  <Button
                    onClick={this.sendRequest}
                    disabled={this.state.disabled}
                    variant={buttonType}
                  >
                    Сохранить
                  </Button>
                  {this.state.incorrectText && (
                    <span className="error-message">
                      {this.state.errorText}
                    </span>
                  )}
                  {this.state.successText && (
                    <span className="success-message">
                      Продукция успешно сохранена
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
