import React from "react";
import { connect } from "react-redux";
import {
  getOptions,
  getRequest,
  sendRequest,
} from "../../helper/forCountries/forCountries";
import Loader from "../../Components/loader/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faCalendarAlt,
  faClipboardList,
  faCreditCard,
  faDollarSign,
  faRecycle,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { translateType } from "../../helper/sort";
import ReactSelect from "../../Components/react-select/react-select";
import Button from "../../Components/Button/Button";

class MainPage extends React.Component {
  state = {
    loader: true,
    disabled: false,
    accountType: this.props.meReducer.data.accountType,
    id: this.props.meReducer.data.id,
    country: [],
    region: [],
    city: [],
    regionId: null,
    cityId: null,
    body: {
      sorting: {
        sortBy: "WEEK",
        sortDirection: "ASC",
      },
      filter: {
        week: null,
        countryId: null,
        regionId: null,
        cityId: null,
      },
    },
    employeeInfo: {},
    partnerBalance: {},
    adminInfo: {
      currentWeek: 0,
      incomeCurrentWeek: 0,
      incomeTotal: 0,
      partnersCountNew: 0,
      partnersCountTotal: 0,
      payedCurrentWeek: 0,
    },
    partnerInfo: {},
    partnerStats: {
      currentWeek: 123,
      childCountLeftCurrentWeek: 27,
      childCountRightCurrentWeek: 35,
      childrenCountLeftTotal: 291,
      childrenCountRightTotal: 351,
      currentCycle: 6,
      currentStage: 3,
    },
  };
  componentDidMount() {
    if (this.state.accountType === "PARTNER") {
      getRequest(`/api/v1/balances`)
        .then((data) =>
          this.setState({ ...this.state, partnerBalance: data.data })
        )
        .catch((e) => console.error(e));
      getRequest(`/api/v1/partners`)
        .then((data) =>
          this.setState({ ...this.state, partnerInfo: data.data }, () =>
            getRequest(`/api/v1/statistics`)
              .then((data) =>
                this.setState({
                  ...this.state,
                  partnerStats: data.data,
                  loader: false,
                })
              )
              .catch((e) => console.error(e))
          )
        )
        .catch((e) => console.error(e));
    } else {
      this.getCountries();
      getRequest(`/api/v1/admin/statistics`)
        .then((data) =>
          this.setState({ ...this.state, adminInfo: data.data }, () =>
            getRequest(`/api/v1/employees/${this.state.id}`)
              .then((data) =>
                this.setState(
                  {
                    ...this.state,
                    employeeInfo: data.data,
                  },
                  () => this.weeksOptions()
                )
              )
              .catch((e) => console.error(e))
          )
        )
        .catch((e) => console.error(e));
    }
  }
  weeksOptions = () => {
    let arr = [{ name: "Все недели", id: null }];
    for (let i = 1; i <= this.state.adminInfo.currentWeek; i += 1) {
      arr.push({ name: i, id: i });
    }
    this.setState({ ...this.state, loader: false, weeks: arr });
  };
  getCountries = () => {
    getRequest("/api/v1/common/countries")
      .then((data) =>
        this.setState({
          country: data.data.data,
        })
      )
      .catch((e) => console.error("error: ", e));
  };
  citiesOnRegion = () => {
    if (this.state.body.filter.regionId !== null) {
      getRequest(
        `/api/v1/common/cities/find-by-region/${this.state.body.filter.regionId}`
      ).then((data) =>
        this.setState({
          ...this.state,
          city: data.data.data,
        })
      );
    }
  };
  regionsOnCountry = () => {
    if (this.state.body.filter.countryId !== null) {
      getRequest(
        `/api/v1/common/regions/find-by-country/${this.state.body.filter.countryId}`
      ).then((data) =>
        this.setState({
          ...this.state,
          region: data.data.data,
        })
      );
    }
  };
  changeAddressId = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        [data]: { label: e ? e.label : null, value: e ? e.value : null },
        body: {
          ...this.state.body,
          filter: { ...this.state.body.filter, [data]: e ? e.value : null },
        },
        incorrectText: false,
        successText: false,
      },
      () => {
        if (data === "countryId") {
          this.setState({
            ...this.state,
            body: {
              ...this.state.body,
              filter: {
                ...this.state.body.filter,
                regionId: null,
                cityId: null,
              },
            },
            region: [],
            city: [],
            regionId: null,
            cityId: null,
          });
        } else if (data === "regionId") {
          this.setState({
            ...this.state,
            body: {
              ...this.state.body,
              filter: {
                ...this.state.body.filter,
                cityId: null,
              },
            },
            city: [],
            cityId: null,
          });
        }
        if (foo !== null) {
          foo();
        }
      }
    );
  };
  exportFile = () => {
    this.setState({ ...this.state, disabled: true });
    sendRequest(
      `/api/v1/admin/statistics/export/weekly`,
      this.state.body,
      "arraybuffer"
    )
      .then((res) => {
        let blob = new Blob([res.data], { type: "application/zip" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Бонус.xlsx";
        link.click();
        this.setState({ ...this.state, disabled: false });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ ...this.state, disabled: false });
      });
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <>
            {this.props.meReducer.data.accountType === "PARTNER" && (
              <div className="inactive-partnership">
                {this.props.meReducer.data.partnershipStatus !== "ACTIVE" && (
                  <span>
                    <b>У вас не активирован статус партнерства!</b>
                  </span>
                )}
              </div>
            )}
            <div>
              {this.state.accountType === "EMPLOYEE" && (
                <>
                  <div className="main-info">
                    <div className="active-header">
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </b>{" "}
                        {this.state.adminInfo.currentWeek}
                        <p>Текущая неделя</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faDollarSign} />
                        </b>{" "}
                        {this.state.adminInfo.incomeCurrentWeek}
                        <p>Закуп на текущей неделе</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faDollarSign} />
                        </b>{" "}
                        {this.state.adminInfo.incomeTotal}
                        <p>Закуп за все время</p>
                      </div>
                    </div>
                    <div className="active-header">
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faUserPlus} />
                        </b>{" "}
                        {this.state.adminInfo.partnersCountNew}
                        <p>Число новых активных партнеров</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faUsers} />
                        </b>{" "}
                        {this.state.adminInfo.partnersCountTotal}
                        <p>Число всех активных партнеров</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faCreditCard} />
                        </b>{" "}
                        {this.state.adminInfo.payedCurrentWeek}
                        <p>Выплачено на текущей неделе</p>
                      </div>
                    </div>
                  </div>
                  {this.props.meReducer.data.role === "ADMIN" && (
                    <div className="categories_bar">
                      <div className="filter-block main">
                        <div className="filter-select">
                          <div className="filter-select-height">
                            {" "}
                            <ReactSelect
                              filterOption={(option) => option}
                              text="Страна"
                              placeholder="Выберите страну"
                              onChange={this.changeAddressId(
                                "countryId",
                                this.regionsOnCountry
                              )}
                              options={getOptions(this.state.country)}
                            />
                          </div>
                          <div className="filter-select-height">
                            <ReactSelect
                              value={this.state.regionId}
                              text="Регион"
                              placeholder="Выберите регион"
                              onChange={this.changeAddressId(
                                "regionId",
                                this.citiesOnRegion
                              )}
                              options={getOptions(this.state.region)}
                            />
                          </div>
                          <div className="filter-select-height">
                            <ReactSelect
                              value={this.state.cityId}
                              text="Город"
                              placeholder="Выберите город"
                              onChange={this.changeAddressId("cityId", null)}
                              options={getOptions(this.state.city)}
                            />
                          </div>
                        </div>
                        <div className="filter-input">
                          <ReactSelect
                            text="Неделя"
                            placeholder="Выберите неделю"
                            onChange={this.changeAddressId("week", null)}
                            options={getOptions(this.state.weeks)}
                          />
                        </div>
                        <div className="filter-button main-button">
                          <Button
                            disabled={this.state.disabled}
                            variant={buttonType}
                            onClick={this.exportFile}
                          >
                            Экспорт статистики
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="new-table">
                    <h2>Админ панель</h2>
                    <ul className="responsive-table">
                      <li className="table-header">
                        <div className="col col-1">ID</div>
                        <div className="col col-2">Логин</div>
                        <div className="col col-3">Тип</div>
                        <div className="col col-4">Статус</div>
                        <div className="col col-3">Email</div>
                        <div className="col col-4">Роль</div>
                      </li>
                      <li className="table-row">
                        <div className="col col-1" data-label="ID">
                          {this.state.employeeInfo.id}
                        </div>
                        <div className="col col-2" data-label="Логин">
                          {this.state.employeeInfo.username}
                        </div>
                        <div className="col col-3" data-label="Тип">
                          {translateType(this.state.employeeInfo.type)}
                        </div>
                        <div className="col col-4" data-label="Статус">
                          {translateType(this.state.employeeInfo.status)}
                        </div>
                        <div className="col col-4" data-label="Email">
                          {this.state.employeeInfo.email}
                        </div>
                        <div className="col col-4" data-label="Роль">
                          {this.state.employeeInfo.role.name}
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              )}
              {this.state.accountType === "PARTNER" && (
                <>
                  <div className="main-info">
                    <div className="active-header">
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </b>{" "}
                        {this.state.partnerStats.currentWeek}
                        <p>Текущая неделя</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faRecycle} />
                        </b>{" "}
                        {this.state.partnerStats.currentCycle}
                        <p>Текущий цикл</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faClipboardList} />
                        </b>{" "}
                        {this.state.partnerStats.currentStage}
                        <p>Текущий этап</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faDollarSign} />
                        </b>{" "}
                        {this.state.partnerBalance.balance}
                        <p>Текущий баланс</p>
                      </div>
                    </div>
                    <div className="active-header">
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faUserPlus} />
                        </b>{" "}
                        {this.state.partnerStats.childCountLeftCurrentWeek}
                        <p>Новых участников слева</p>
                        <p>в текущей неделе</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faUserPlus} />
                        </b>{" "}
                        {this.state.partnerStats.childCountRightCurrentWeek}
                        <p>Новых участников справа</p>
                        <p>в текущей неделе</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faAddressBook} />
                        </b>{" "}
                        {this.state.partnerStats.childrenCountLeftTotal}
                        <p>Общее количество участников слева</p>
                      </div>
                      <div className="widget">
                        <b>
                          <FontAwesomeIcon icon={faAddressBook} />
                        </b>{" "}
                        {this.state.partnerStats.childrenCountRightTotal}
                        <p>Общее количество участников справа</p>
                      </div>
                    </div>
                  </div>
                  <div className="new-table">
                    <h2>Информационная панель</h2>
                    <ul className="responsive-table">
                      <li className="table-header">
                        <div className="col col-1">ID</div>
                        <div className="col col-2">Код</div>
                        <div className="col col-2">Логин</div>
                        <div className="col col-3">Ф.И.О</div>
                        <div className="col col-3">Email</div>
                        <div className="col col-4">Статус партнерства</div>
                        <div className="col col-4">Квалификация</div>
                        <div className="col col-4">Ментор</div>
                        <div className="col col-4">Наставник</div>
                        <div className="col col-2">Название банка</div>
                        <div className="col col-3">Номер карты</div>
                        <div className="col col-2">Орган выдавший паспорт</div>
                        <div className="col col-2">Код паспорта</div>
                        <div className="col col-2">Дата выдачи</div>
                        <div className="col col-4">ИНН</div>
                      </li>
                      <li className="table-row">
                        <div className="col col-1" data-label="ID">
                          {this.state.partnerInfo.id}
                        </div>
                        <div className="col col-2" data-label="Код">
                          {this.state.partnerInfo.code}
                        </div>
                        <div className="col col-2" data-label="Логин">
                          {this.state.partnerInfo.username}
                        </div>
                        <div className="col col-4" data-label="Ф.И.О">
                          <p>
                            {this.state.partnerInfo.surname}
                            {"  "}
                          </p>{" "}
                          <p>{this.state.partnerInfo.firstName}</p>{" "}
                          <p>{this.state.partnerInfo.patronymic}</p>
                        </div>
                        <div className="col col-3" data-label="Email">
                          {this.state.partnerInfo.email}
                        </div>
                        <div
                          className="col col-2"
                          data-label="Статус партнерства"
                        >
                          {translateType(
                            this.state.partnerInfo.partnershipStatus
                          )}
                        </div>
                        <div className="col col-2" data-label="Квалификация">
                          {this.state.partnerInfo.qualification}
                        </div>
                        <div className="col col-4" data-label="Ментор">
                          {this.state.partnerInfo.mentor !== null &&
                            this.state.partnerInfo.mentor.surname !== null && (
                              <>
                                <p>{this.state.partnerInfo.mentor.surname}</p>
                                <p>{this.state.partnerInfo.mentor.firstName}</p>
                                <p>
                                  {this.state.partnerInfo.mentor.patronymic}
                                </p>
                              </>
                            )}
                        </div>
                        <div className="col col-4" data-label="Наставник">
                          {this.state.partnerInfo.proposer !== null &&
                            this.state.partnerInfo.proposer.surname !==
                              null && (
                              <>
                                <p>{this.state.partnerInfo.proposer.surname}</p>
                                <p>
                                  {this.state.partnerInfo.proposer.firstName}
                                </p>
                                <p>
                                  {this.state.partnerInfo.proposer.patronymic}
                                </p>
                              </>
                            )}
                        </div>
                        <div className="col col-2" data-label="Название банка">
                          {this.state.partnerInfo.bankName}
                        </div>
                        <div className="col col-3" data-label="Номер карты">
                          {this.state.partnerInfo.bankCardNumber}
                        </div>
                        <div
                          className="col col-2"
                          data-label="Орган выдавший паспорт"
                        >
                          {this.state.partnerInfo.passport.issuedBy}
                        </div>
                        <div className="col col-2" data-label="Код паспорта">
                          {this.state.partnerInfo.passport.code}
                        </div>
                        <div className="col col-2" data-label="Дата выдачи">
                          {this.state.partnerInfo.passport.issuedDate
                            ? this.state.partnerInfo.passport.issuedDate
                            : this.state.partnerInfo.passport.issuedDateString}
                        </div>
                        <div className="col col-4" data-label="ИНН">
                          {this.state.partnerInfo.passport.itn}
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};
export default connect(mapStateToProps, null)(MainPage);
