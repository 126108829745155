import { Redirect } from "react-router-dom";
import SignInPage from "./sign-in/SignIn";
import MainPage from "./container/MainPage/MainPage";
import React from "react";
import AllEmployee from "./container/employee/AllEmployee";
import {Users} from "./container/render";
import EditPartner from "./container/partners/EditPartner/EditPartner";
import AllPartners from "./container/partners/AllPartners";
import AddPartner from "./container/partners/AddPartner/AddPartner";
import AllCountry from "./container/country/country/allCountry";
import AllRegions from "./container/country/region/allRegions";
import AllCities from "./container/country/city/allCities";
import ProductCategories from "./container/product-categories/categories";
import CustomCycleSettings from "./container/cycles/customCycleSettings";
import Structure from "./container/structure/structure";
import BonusInfo from "./container/bonusInfo/bonusInfo";
import BonusInfoOverall from "./container/bonus-info-overall/bonus-info-overall";
import PartnerQualificationHistory from "./container/partner-qualifications-history/partner-qualification-history";
import PurchaseReport from "./container/PurchaseReport/purchaseReport";
import Import from './container/Import'

export const ROUTES = [
  {
    check: (isLogged) => isLogged,
    path: "/",
    component: MainPage,
    exact: true,
  },
  {
    check: (isLogged) => !isLogged,
    path: "/sign-in",
    component: SignInPage,
    exact: true,
  },
  {
    check: (isLogged) => isLogged,
    path: "/users",
    component: Users,
    routes: [
      {
        path: "/users/all-products-categories",
        component: ProductCategories,
        exact: true,
      },
      {
        path: "/users/all-employee",
        component: AllEmployee,
        exact: true
      },
      {
        path: "/users/add-partner",
        component: AddPartner,
        exact: true,
      },
      {
        path: "/users/all-partners",
        component: AllPartners,
        exact: true
      },
      {
        path:  `/users/edit-partner/:id`,
        component: EditPartner,
        exact: true
      },
      {
        path:  `/users/all-countries`,
        component: AllCountry,
        exact: true
      },
      {
        path:  `/users/all-regions/:id`,
        component: AllRegions,
        exact: true,
      },
      {
        path:  `/users/all-cities/:id`,
        component: AllCities,
        exact: true,
      },
      {
        path:  `/users/all-cycles`,
        component: CustomCycleSettings,
        exact: true,
      },
      {
        path:  `/users/bonus-info`,
        component: BonusInfo,
        exact: true,
      },
      {
        path:  `/users/bonus-info-overall`,
        component: BonusInfoOverall,
        exact: true,
      },
      {
        path:  `/users/partner-qualifications`,
        component: PartnerQualificationHistory,
        exact: true,
      },
      {
        path:  `/users/purchase-info`,
        component: PurchaseReport,
        exact: true,
      },
      {
        path:  `/users/structure`,
        component: Structure,
        exact: true,
      },
      {
        path:  `/users/import`,
        component: Import,
        exact: true,
      },
    ],
  },
  {
    check: (isLogged) => !isLogged,
    path: "*",
    component: () => <Redirect to="/sign-in" />,
  },
  {
    path: "*",
    check: (isLogged) => isLogged,
    component: () => <Redirect to="/" />,
  },
];
