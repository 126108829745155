import React from "react";
import {getMe} from "../../../redux/actions/getMe";
import {connect} from "react-redux";
import {NavLink, Link} from "react-router-dom";
import "./nav-index.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUsersCog,
  faHandHoldingUsd,
  faSignOutAlt,
  faMapMarkedAlt,
  faHome,
  faTree,
  faShoppingBag,
  faCaretDown, faHistory, faHandPaper,
} from "@fortawesome/free-solid-svg-icons";
import {getRequest} from "../../../helper/forCountries/forCountries";

class NavBar extends React.Component {
  state = {
    data: this.props.data,
    show: false,
  };
  goToSignPage = () => {
    this.props.getMe();
  };

  render() {
    return (
      <div className="navbar">
        <Link className="nav_link" to="/">
          <FontAwesomeIcon icon={faHome}/> {this.state.data.username}
        </Link>
        {this.state.data.role === 'SYSTEM_ADMIN' &&
        <Link className="nav_link" to="/users/import">
          Импорт
        </Link>
        }
        <div className="nav">
          <div className="web-media">
            <NavLink
              activeClassName="is-active"
              className="nav_link"
              to="/users/structure"
            >
              <FontAwesomeIcon icon={faTree}/> Структура
            </NavLink>
            {this.state.data.accountType === "EMPLOYEE" && (
              <>
                {this.state.data.role === "ADMIN" && (
                  <NavLink
                    activeClassName="is-active"
                    className="nav_link"
                    to="/users/all-employee"
                  >
                    <FontAwesomeIcon icon={faUsersCog}/> Администраторы
                  </NavLink>)}

                <NavLink
                  activeClassName="is-active"
                  className="nav_link"
                  to="/users/all-partners"
                >
                  <FontAwesomeIcon icon={faUsers}/> Партнеры
                </NavLink>
                <NavLink
                  activeClassName="is-active"
                  className="nav_link"
                  to="/users/all-countries"
                >
                  <FontAwesomeIcon icon={faMapMarkedAlt}/> Регионы
                </NavLink>
              </>
            )}
          </div>
          <div className="dropdown">
            <div className="dropbtn nav_link">
              <FontAwesomeIcon icon={faCaretDown}/>{" "}
              меню
            </div>
            <div className="dropdown-content">
              {(this.state.data.role !== "OPERATOR") &&
              <> <NavLink
                to="/users/bonus-info"
                activeClassName="is_active"
                className="drop_link"
              >
                <FontAwesomeIcon icon={faHandHoldingUsd}/> Бонусы
              </NavLink>
                {this.state.data.accountType === 'EMPLOYEE' && (
                  <><NavLink
                    to="/users/bonus-info-overall"
                    activeClassName="is_active"
                    className="drop_link"
                  >
                    <FontAwesomeIcon icon={faHandHoldingUsd}/> Общая ведомость
                  </NavLink>
                    <NavLink
                      activeClassName="is_active"
                      className="drop_link"
                      to="/users/partner-qualifications"
                    >
                      <FontAwesomeIcon icon={faHandPaper}/> Квалифкации
                    </NavLink>
                  </>
                )}
                <NavLink
                  activeClassName="is_active"
                  className="drop_link"
                  to="/users/purchase-info"
                >
                  <FontAwesomeIcon icon={faHistory}/> История покупок
                </NavLink>
              </>}
              <NavLink
                activeClassName="is_active"
                className="drop_link"
                to="/users/all-products-categories"
              >
                <FontAwesomeIcon icon={faShoppingBag}/> Продукция
              </NavLink>
              <div className="phone-media">
                <NavLink
                  activeClassName="is-active"
                  className="drop_link"
                  to="/users/structure"
                >
                  <FontAwesomeIcon icon={faTree}/> Структура
                </NavLink>
                {this.state.data.accountType === "EMPLOYEE" && (
                  <>
                    {this.state.data.role === "ADMIN" && (

                      <NavLink
                        activeClassName="is-active"
                        className="drop_link"
                        to="/users/all-employee"
                      >
                        <FontAwesomeIcon icon={faUsersCog}/> Администраторы
                      </NavLink>
                    )}
                    <NavLink
                      activeClassName="is-active"
                      className="drop_link"
                      to="/users/all-partners"
                    >
                      <FontAwesomeIcon icon={faUsers}/> Партнеры
                    </NavLink>
                    <NavLink
                      activeClassName="is-active"
                      className="drop_link"
                      to="/users/all-countries"
                    >
                      <FontAwesomeIcon icon={faMapMarkedAlt}/> Регионы
                    </NavLink>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="nav_link" onClick={this.logOut}>
            <FontAwesomeIcon icon={faSignOutAlt}/> Выйти
          </div>
        </div>
      </div>
    );
  }

  logOut = () => {
    getRequest("/api/v1/logout").then(() => {
      this.goToSignPage();
    });
  };
}

const mapStateToProps = (state) => {
  return {
    meReducer: state.meReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMe: () => dispatch(getMe()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
