import React from "react";
import { sendRequest } from "../../helper/forCountries/forCountries";
import Input from "../../Components/input/Input";
import {
  validationForNumeric,
  validationOnUsersName,
} from "../../helper/validateHelper/validateHelper";
import Button from "../../Components/Button/Button";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class AddProduct extends React.Component {
  state = {
    body: {
      name: "",
      price: "",
      categoryId: this.props.id,
      code: "",
    },
    disabled: true,
    name: "",
    price: "",
    successText: false,
    incorrectText: false,
    errorText: "",
  };
  sendRequest = () => {
    sendRequest("/api/v1/store/products", this.state.body)
      .then(() => this.setState({ ...this.state, successText: true }))
      .catch((e) =>
        this.setState({
          ...this.state,
          incorrectText: true,
          successText: false,
          errorText: e.response.data.message,
        })
      );
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: validationOnUsersName(this.state.body[data], length),
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateForNumeric = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: validationForNumeric(this.state.body[data]),
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isDisabledButton = () => {
    const state = this.state;
    return state.name === false && state.price === false;
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <div className="modal-page">
        <span>Добавить продукцию</span>
        <div className="categories_bar">
          <Input
            star={true}
            text="Название товара"
            placeholder="Введите название товара"
            value={this.state.body.name}
            onChange={this.changeUserData(
              "name",
              this.validateUsersDataLength("name", 1)
            )}
            onBlur={this.validateUsersDataLength("name", 1)}
            required={this.state.name}
            validationMessage="Поле не должно быть пустым"
          />
          <Input
            star={true}
            text="Цена товара"
            placeholder="Введите цену товара"
            value={this.state.body.price}
            onChange={this.changeUserData(
              "price",
              this.validateForNumeric("price")
            )}
            onBlur={this.validateForNumeric(this.state.body.price)}
            required={this.state.price}
            validationMessage="Поле не должно быть пустым и должен содержать только цифры"
          />
          <Input
            star={true}
            text="Код товара"
            placeholder="Введите код товара"
            value={this.state.body.code}
            onChange={this.changeUserData(
              "code",
              this.validateUsersDataLength("code", 1)
            )}
            onBlur={this.validateUsersDataLength("code", 1)}
            required={this.state.code}
            validationMessage="Поле не должно быть пустым"
          />
          <div className="active-header">
            <Button
              variant={buttonType}
              onClick={this.sendRequest}
              disabled={this.state.disabled}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Добавить
            </Button>
            {this.state.incorrectText && (
              <span className="error-message">
                {this.state.errorText}
              </span>
            )}
            {this.state.successText && (
              <span className="success-message">
                Продукция успешно добавлена
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
