import React from "react";
import ReactSelect from "../../Components/react-select/react-select";
import Button from "../../Components/Button/Button";
import {
  getOptions,
  sendRequestToEdit,
} from "../../helper/forCountries/forCountries";

export default class Status extends React.Component {
  state = {
    body: {
      status: this.props.data.status,
    },
    status: [
      { id: "DISABLED", name: "Неактивный" },
      { id: "ACTIVE", name: "Активный" },
    ],
    incorrectText: false,
    successText: false,
    errorText: "",
  };
  sendRequest = () => () => {
    sendRequestToEdit(
      `/api/v1/admin/partners/${this.props.data.id}/status`,
      this.state.body
    )
      .then(() =>
        this.setState({
          ...this.state,
          successText: true,
          incorrectText: false,
        })
      )
      .catch((e) =>
        this.setState({
          ...this.state,
          incorrectText: true,
          successText: false,
          errorText: e.response.data.message
        })
      );
  };
  changeStatus = (e) => {
      this.setState({...this.state, body: {status: e ? e.value : null}});
    };
  render() {
    let disabled = false;
    let buttonType = "secondary";
    if (this.state.body.status === null) {
      buttonType = "primary";
      disabled = true;
    }
    let idCode = 0;
    if (this.props.data.status === "ACTIVE") {
      idCode = 1;
    }
    return (
      <div className="modal-page">
        <span>Изменить статус</span>
        <div className="categories_bar">
        <ReactSelect
          defaultValue={{
            label: this.state.status[idCode].name,
            value: this.state.status[idCode].id,
          }}
          text="Статус"
          placeholder="Выберите статус..."
          options={getOptions(this.state.status)}
          onChange={this.changeStatus}
        />
        <div className="active-header">
        <Button disabled={disabled} variant={buttonType} onClick={this.sendRequest()}>
          Сохранить
        </Button>
          {this.state.incorrectText && (
            <span className="error-message">{this.state.errorText}</span>
          )}
          {this.state.successText && <span className="success-message">Изменен</span>}
        </div>
      </div>
      </div>
    );
  }
}
