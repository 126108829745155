import React from "react";
import { sendRequestToEdit } from "../../helper/forCountries/forCountries";
import Input from "../../Components/input/Input";
import Button from "../../Components/Button/Button";
import { validationOnUsersName } from "../../helper/validateHelper/validateHelper";

export default class ChangePassword extends React.Component {
  state = {
    passwordBody: {
      password: "",
    },
    password: {
      password: "",
      touched: false,
    },
    type: "password",
    incorrectText: false,
    successText: false,
    disabled: false,
    errorText: "",
  };
  sendRequest = () => {
    sendRequestToEdit(this.props.url, this.state.passwordBody)
      .then(() =>
        this.setState({
          ...this.state,
          successText: true,
          incorrectText: false,
        })
      )
      .catch((e) =>
        this.setState({
          ...this.state,
          incorrectText: true,
          successText: false,
          errorText: e.response.data.message,
        })
      );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(this.state.passwordBody[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isTouched = (data, foo) => () => {
    this.setState(
      { ...this.state, [data]: { ...this.state[data], touched: true } },
      () => foo()
    );
  };
  isRequired = (data) => () => {
    return this.state[data][data] === true && this.state[data].touched === true;
  };
  isDisabledButton = () => {
    const state = this.state;
    return state.password.password === false;
  };
  viewPassword = () => {
    this.setState({ ...this.state, type: "text" });
  };
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        passwordBody: { ...this.state.passwordBody, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <div className="modal-page">
        <span>Пароль</span>
        <div className="categories_bar">
          <div className="active-header">
            <Input
              star={true}
              autoComplete="new-password"
              // type={this.state.type}
              text="Пароль"
              value={this.state.passwordBody.password}
              onChange={this.changeUserData(
                "password",
                this.validateUsersDataLength("password", 6)
              )}
              onBlur={this.isTouched(
                "password",
                this.validateUsersDataLength("password", 1)
              )}
              required={this.isRequired("password")()}
              validationMessage="Длина должна быть больше 5 символов"
            />
          </div>
          <div className="active-header">
            <Button
              disabled={this.state.disabled}
              variant={buttonType}
              onClick={this.sendRequest}
            >
              Сохранить
            </Button>
            {this.state.incorrectText && (
              <span className="error-message">
                {this.state.errorText}
              </span>
            )}
            {this.state.successText && (
              <span className="success-message">Успешно изменен</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
