export const sortFn = (data) => {
  return data.sort(function (a, b) {
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    // a должно быть равным b
    return 0;
  });
};
export const arrValue = (value) => {
  const arr1 = [
    "PARTNERSHIP_ACTIVATED",
    "MENTOR_ORGANIZATIONAL_BONUS",
    "PROPOSER_ORGANIZATIONAL_BONUS",
    "ORGANIZATIONAL_STAGE_COMPLETED",
    "SPONSOR_BONUS",
    "PASSIVE_BONUS",
  ];
  const arr2 = [
    "Активизация партнера",
    "Организаторский бонус наставника",
    "Организаторский бонус рекомендателя",
    "Бонусы на этапах",
    "Спонсорский Бонус",
    "Пасивный бонус",
  ];
  return arr2[arr1.indexOf(value)]
};
export const translateType = (value) => {
 const arr1 = ["EMPLOYEE", "PARTNER", "ACTIVE", "DISABLED", "INACTIVE", "DEACTIVATED"]
  const arr2 = ["Администратор", "Партнёр", "Активный", "Неактивный", "Неактивный", "Неактивный"]
  return arr2[arr1.indexOf(value)]
}
export const firstLetter = (value) => {
  const result = Array.from(value)
  return result[0]
}
export const incomeType = [
  { id: null, name: "Все категории" },
  { id: "PARTNERSHIP_ACTIVATED", name: "Активизация партнера" },
  {
    id: "MENTOR_ORGANIZATIONAL_BONUS",
    name: "Организаторский бонус ментора",
  },
  {
    id: "PROPOSER_ORGANIZATIONAL_BONUS",
    name: "Организаторский бонус наставника",
  },
  { id: "ORGANIZATIONAL_STAGE_COMPLETED", name: "Бонусы на этапах" },
  { id: "SPONSOR_BONUS", name: "Спонсорский Бонус" },
  { id: "PASSIVE_BONUS", name: "Пасивный бонус" },
]
export const bankCardNumber = (value) => {
let arr = Array.from(value.toString())
  let result = [];
  for(let i = 0; i < arr.length; i++){
    if(i === 4 || i === 8 || i === 12){
      result.push("-")
    }
    result.push(arr[i])
  }
  return result.join("")
}