import React from "react";
import "./input.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default class PasswordInput extends React.Component {
  state = {
    vision: false,
  }
   changeInputType = () => {
  this.setState({vision: !this.state.vision})
  };
   render() {
     let type = "password"
     if(this.state.vision){
       type = "text"
     }
     else {
       type = "password"
     }
     const {variant = "basic"} = this.props;
     return (
       <div className={`${this.props.className} ${variant}`}>
         <label>
           {this.props.text}
           {this.props.star === true && <span className="require">*</span>}
         </label>
         <input
           onKeyPress={this.props.onKeyPress}
           autoComplete={this.props.autoComplete}
           type={type}
           min={this.props.min}
           max={this.props.max}
           value={this.props.value}
           onBlur={this.props.onBlur}
           onFocus={this.props.onFocus}
           ref={this.props.inputRef}
           disabled={this.props.disabled}
           onChange={this.props.onChange}
           maxLength={this.props.maxLength}
           minLength={this.props.minLength}
           placeholder={this.props.placeholder}
           required={this.props.required}
         />
         <div className="iconEye">
         <span className="cursor-eye" onClick={this.changeInputType}>
        {this.state.vision ? (
          <FontAwesomeIcon icon={faEyeSlash}/>
        ) : (
          <FontAwesomeIcon icon={faEye}/>
        )}
      </span></div>
         <span className="validation-message">
        {this.props.required === true && <span>{this.props.validationMessage}</span>}
      </span>
       </div>
     );
   }
}
