import React from "react";
import { bankCardNumber, translateType } from "../../../helper/sort";

export default class PartnerInfo extends React.Component {
  state = {
    info: this.props.info,
  };
  render() {
    return (
      <div className="modal-page">
        <div className="new-table-info">
          <ul className="responsive-table-info">
            <li className="table-header">
              <div className="col col-2">Код</div>
              <div className="col col-3">Ф.И.О</div>
              <div className="col col-3">Email</div>
              <div className="col col-3">Адрес</div>
              <div className="col col-2">Название банка</div>
              <div className="col col-3">Номер карты</div>
              <div className="col col-2">Орган выдавший паспорт</div>
              <div className="col col-2">Код паспорта</div>
              <div className="col col-2">Дата выдачи</div>
              <div className="col col-4">ИНН</div>
              <div className="col col-4">Зарегистрированная неделя</div>
              <div className="col col-4">Статус партнерства</div>
              <div className="col col-4">Квалификация</div>
              <div className="col col-4">Ментор</div>
              <div className="col col-4">Наставник</div>
              <div className="col col-4">Слева текущий</div>
              <div className="col col-4">Справ текущий</div>
              <div className="col col-4">Этап</div>
              <div className="col col-4">Цикл</div>
            </li>
            <li className="table-row">
              <div className="col col-2" data-label="Код">
                {this.state.info.code}
              </div>
              <div className="col col-4" data-label="Ф.И.О">
                <p>
                  {this.state.info.surname}
                  {"  "}
                </p>{" "}
                <p>{this.state.info.firstName}</p>{" "}
                <p>{this.state.info.patronymic}</p>
              </div>
              <div className="col col-3" data-label="Email">
                {this.state.info.email}
              </div>
              <div className="col col-3" data-label="Адрес">
                {this.state.info.address.country ? (
                  <span>{this.state.info.address.country.name}</span>
                ) : (
                  <span>{""}</span>
                )}
                {this.state.info.address.city ? (
                  <span>{this.state.info.address.city.name}</span>
                ) : (
                  <span>{""}</span>
                )}
                {this.state.info.address.street ? (
                  <span>{this.state.info.address.street}</span>
                ) : (
                  <span>{""}</span>
                )}
                {this.state.info.address.house ? (
                  <span>{this.state.info.address.house}</span>
                ) : (
                  <span>{""}</span>
                )}
              </div>
              <div className="col col-2" data-label="Название банка">
                {this.state.info.bankName}
              </div>
              <div className="col col-3" data-label="Номер карты">
                {this.state.info.bankCardNumber ? (
                  bankCardNumber(this.state.info.bankCardNumber)
                ) : (
                  <span>{""}</span>
                )}
              </div>
              <div className="col col-2" data-label="Орган выдавший паспорт">
                {this.state.info.passport.issuedBy}
              </div>
              <div className="col col-2" data-label="Код паспорта">
                {this.state.info.passport.code}
              </div>
              <div className="col col-2" data-label="Дата выдачи">
                {this.state.info.passport.issuedDate
                  ? this.state.info.passport.issuedDate
                  : this.state.info.passport.issuedDateString}
              </div>
              <div className="col col-4" data-label="ИНН">
                {this.state.info.passport.itn}
              </div>
              <div className="col col-2" data-label="Неделя регистрации">
                {this.state.info.createdWeek}
              </div>
              <div className="col col-2" data-label="Статус партнерства">
                {translateType(this.state.info.partnershipStatus)}
              </div>
              <div className="col col-2" data-label="Квалификация">
                {this.state.info.qualification}
              </div>
              <div className="col col-4" data-label="Ментор">
                {this.state.info.mentor !== null &&
                  this.state.info.mentor.surname !== null && (
                    <>
                      <p>{this.state.info.mentor.surname}</p>
                      <p>{this.state.info.mentor.firstName}</p>
                      <p>{this.state.info.mentor.patronymic}</p>
                    </>
                  )}
              </div>
              <div className="col col-4" data-label="Наставник">
                {this.state.info.proposer !== null &&
                  this.state.info.proposer.surname !== null && (
                    <>
                      <p>{this.state.info.proposer.surname}</p>
                      <p>{this.state.info.proposer.firstName}</p>
                      <p>{this.state.info.proposer.patronymic}</p>
                    </>
                  )}
              </div>
              <div className="col col-2" data-label="Слева текущий">
                {this.state.info.stage.leftCurrent}
              </div>
              <div className="col col-2" data-label="Справ текущий">
                {this.state.info.stage.rightCurrent}
              </div>
              <div className="col col-2" data-label="Этап">
                {this.state.info.stage.stage}
              </div>
              <div className="col col-2" data-label="Цикл">
                {this.state.info.stage.cycle}
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
// <div className="active-block">
//   <div className="partner-info">
//     <p>
//       Код: <i><b>{this.state.info.code}</b></i>
//     </p>
//     <p>
//       Ф.И.О:{" "}
//       <i><b>
//         {this.state.info.surname} {this.state.info.firstName}{" "}
//         {this.state.info.patronymic ? (
//           <span>{this.state.info.patronymic}</span>
//         ) : (
//           <span>{""}</span>
//         )}
//       </b></i>
//     </p>
//     <p>
//       Email: <i><b>{this.state.info.email}</b></i>
//     </p>
//     <p>
//       Страна:{" "}
//       <i><b>
//         {this.state.info.address.country ? (
//           <span>{this.state.info.address.country.name}</span>
//         ) : (
//           <span>{""}</span>
//         )}
//       </b></i>
//     </p>
//     <p>
//       Регион:{" "}
//       <i><b>
//         {this.state.info.address.region ? (
//           <span>{this.state.info.address.region.name}</span>
//         ) : (
//           <span>{""}</span>
//         )}
//       </b></i>
//     </p>
//     <p>
//       Город:{" "}
//       <i> <b>
//         {this.state.info.address.city ? (
//           <span>{this.state.info.address.city.name}</span>
//         ) : (
//           <span>{""}</span>
//         )}
//         {this.state.info.address.street ? (
//           <span>{this.state.info.address.street}</span>
//         ) : (
//           <span>{""}</span>
//         )}
//         {this.state.info.address.house ? (
//           <span>{this.state.info.address.house}</span>
//         ) : (
//           <span>{""}</span>
//         )}
//       </b></i>
//     </p>
//     <p>
//       Название банка: <i><b>{this.state.info.bankName}</b></i>
//     </p>
//     <p>
//       Номер карты:{" "}
//       <i><b>
//         {this.state.info.bankCardNumber ? (
//           bankCardNumber(this.state.info.bankCardNumber)
//         ) : (
//           <span>{""}</span>
//         )}
//       </b></i>
//     </p>
//     <p>
//       Орган выдавший паспорт: <i><b>{this.state.info.passport.issuedBy}</b></i>
//     </p>
//     <p>
//       Дата выдачи паспорта: <i><b>{this.state.info.passport.issuedDate}</b></i>
//     </p>
//     <p>
//       Номер паспорта: <i><b>{this.state.info.passport.code}</b></i>
//     </p>
//     <p>
//       ИНН: <i><b>{this.state.info.passport.itn}</b></i>
//     </p>
//   </div>
//   <div className="partner-info">
//     <p>
//       Зарегистрированная неделя:{" "}
//       <i><b>{this.state.info.createdWeek} неделя</b></i>
//     </p>
//     <p>
//       Квалификация: <i><b>{this.state.info.qualification}</b></i>
//     </p>
//     <p>
//       Статус партнерства:{" "}
//       <i><b>{translateType(this.state.info.partnershipStatus)}</b></i>
//     </p>
//   </div>
//   <p>
//     Наставник:{" "}
//     <i><b>
//       {this.state.info.mentor !== null ? (
//         <span>
//                     {this.state.info.mentor.surname}{" "}
//           {this.state.info.mentor.firstName}{" "}
//           {this.state.info.mentor.patronymic ? (
//             <span>{this.state.info.mentor.patronymic}</span>
//           ) : (
//             <span>{""}</span>
//           )}
//                   </span>
//       ) : (
//         <span> </span>
//       )}
//     </b></i>
//   </p>
//   <p>
//     Рекомендатель:{" "}
//     <i><b>
//       {this.state.info.proposer !== null ? (
//         <span>
//                     {this.state.info.proposer.surname}{" "}
//           {this.state.info.proposer.firstName}{" "}
//           {this.state.info.proposer.patronymic ? (
//             <span>{this.state.info.proposer.patronymic}</span>
//           ) : (
//             <span>{""}</span>
//           )}
//                   </span>
//       ) : (
//         <span> </span>
//       )}
//     </b></i>
//   </p>
// </div>
