import React from "react";
import Input from "../../../Components/input/Input";
import {
  getRequest,
  sendRequestToEdit,
} from "../../../helper/forCountries/forCountries";
import Button from "../../../Components/Button/Button";
import {
  validationForNumeric,
  validationOnUsersName,
} from "../../../helper/validateHelper/validateHelper";
import Loader from "../../../Components/loader/loader";

export default class EditCountry extends React.Component {
  state = {
    body: {
      name: "",
      key: "",
    },
    name: {
      name: false,
      touched: false,
    },
    key: { key: false, touched: false },
    disabled: true,
    incorrectText: false,
    successText: false,
    loader: true,
    errorText: "",
  };
  componentDidMount() {
    getRequest(`/api/v1/common/countries/${this.props.id}`).then((data) =>
      this.setState({
        ...this.state,
        body: {
          ...this.state.body,
          name: data.data.name,
          key: data.data.id,
        },
        disabled: false,
        loader: false,
      })
    );
  }
  changeUserData = (data, foo) => (e) => {
    this.setState(
      {
        ...this.state,
        body: { ...this.state.body, [data]: e.target.value },
        incorrectText: false,
        successText: false,
      },
      () => {
        foo();
      }
    );
  };
  isSendRequest = (url, body) => () => {
    return sendRequestToEdit(url, body)
      .then(() => {
        this.setState({ ...this.state, successText: true });
      })
      .catch((e) =>
        this.setState({
          ...this.state,
          incorrectText: true,
          errorText: e.response.data.message,
        })
      );
  };
  validateUsersDataLength = (data, length) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationOnUsersName(this.state.body[data], length),
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  validateForNumeric = (data) => () => {
    this.setState(
      {
        ...this.state,
        [data]: {
          ...this.state[data],
          [data]: validationForNumeric(this.state.body[data]),
          touched: true,
        },
      },
      () => this.setState({ ...this.state, disabled: !this.isDisabledButton() })
    );
  };
  isDisabledButton = () => {
    const state = this.state;
    return state.name.name === false;
  };
  render() {
    let buttonType = "primary";
    if (this.state.disabled === false) {
      buttonType = "secondary";
    }
    return (
      <>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader className="center" width="25px" height="25px" />
          </div>
        ) : (
          <div className="modal-page">
            <h2>{this.state.body.name}</h2>
            <div className="categories_bar">
              <Input
                star={true}
                text="Название cтраны"
                value={this.state.body.name}
                onChange={this.changeUserData(
                  "name",
                  this.validateUsersDataLength("name", 1)
                )}
                onBlur={this.validateUsersDataLength("name", 1)}
                required={this.state.name.name}
                validationMessage="Поле не должно быть пустым"
              />
              <Input
                star={true}
                text="ID страны"
                value={this.state.body.key}
                onChange={this.changeUserData(
                  "key",
                  this.validateForNumeric("key")
                )}
                onBlur={this.validateForNumeric("key")}
                required={this.state.key.key}
                validationMessage="Поле не должно быть пустым и содержать только цифру(ы)"
              />
              <div className="active-header">
                <Button
                  disabled={this.state.disabled}
                  variant={buttonType}
                  onClick={this.isSendRequest(
                    `/api/v1/common/countries/${this.props.id}`,
                    this.state.body
                  )}
                >
                  Изменить
                </Button>
                {this.state.incorrectText && (
                  <span className="error-message">{this.state.errorText}</span>
                )}
                {this.state.successText && (
                  <span className="success-message">
                    {" "}
                    Пользователь успешно сохранен
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
